import { Button, IconButton } from '@mui/material';
import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../contexts/GlobalState';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

function SimpleCaptchaForm() {
    const { handleReset, handleSubmitCaptch, isHuman, setIsHuman, captchaInput, setCaptchaInput,captcha } = useContext(GlobalContext)

    // const [captchaInput, setCaptchaInput] = useState('');
    // const [isHuman, setIsHuman] = useState(false);
    // const [randomNum1, setRandomNum1] = useState(Math.floor(Math.random() * 10));
    // const [randomNum2, setRandomNum2] = useState(Math.floor(Math.random() * 10));

    const handleCaptchaChange = (e) => {
        setCaptchaInput(e.target.value);
        setIsHuman(captcha === e.target.value);

    };

    // const handleReset = () => {
    //     setCaptchaInput('');
    //     setIsHuman(false);
    //     setRandomNum1(Math.floor(Math.random() * 10));
    //     setRandomNum2(Math.floor(Math.random() * 10));
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault(); // Prevent the default form submission behavior
    //     if (!isHuman) {
    //         alert("Please solve the CAPTCHA correctly");
    //         return;
    //     }
    //     setCaptchaInput('');
    //     setCaptcha({isHuman})
    //     alert("Form submitted successfully!"); // Replace with actual form submission logic
    // };

    return (
        <>
            {/* <form onSubmit={handleSubmit}> Attach onSubmit here */}
            <div className='cptcha-btn'>
                <div className='captch-box'>{captcha}</div>
                <label>
                
                    <input
                        type="text"
                        value={captchaInput}
                        onChange={handleCaptchaChange}
                    />
                </label>
                {/* <IconButton aria-label="delete" onClick={() => handleReset(setCaptchaInput, setRandomNum1, setRandomNum2)}> */}
                    <RestartAltIcon style={{cursor:"pointer"}} onClick={handleReset}/>
                {/* </IconButton> */}

                {/* <Button variant='contained' style={{}} onClick={() => handleReset(setCaptchaInput, setRandomNum1, setRandomNum2)}>Reset CAPTCHA</Button> */}
            </div>
            <div >
                {/* <Button variant='contained' type="submit" 
                disabled={!isHuman} 
                onClick={(e)=>handleSubmitCaptch(e,setCaptchaInput)}>Submit</Button> */}
            </div>
            {/* // </form> */}
        </>

    );
}

export default SimpleCaptchaForm;
