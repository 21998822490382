import { useEffect } from 'react';

function useTimeoutToggle(setState, duration, initialValue = false) {
    useEffect(() => {
        setState(initialValue); // Set the initial value when the hook is called

        const timer = setTimeout(() => {
            setState(!initialValue); // Toggle the state after the duration
        }, duration);

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, [setState, duration, initialValue]);
}

export default useTimeoutToggle;
