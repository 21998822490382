import React, { createContext, useState } from 'react';
import generateCaptcha from '../Constant/functions/generateCaptcha';

// Create the Context
export const GlobalContext = createContext();

// Create the Provider Component
export const GlobalProvider = ({ children }) => {
  const [showBookModal, setShowBookModal] = useState(false);
  const [isHuman, setIsHuman] = useState(false);
  const [ captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState('');
  const handleCloseBookModal = () => setShowBookModal(false);
  const handleShowBookModal = () => setShowBookModal(true);

  const handleReset = () => {
    setCaptchaInput('');
    setIsHuman(false);
    setCaptcha(generateCaptcha())
  };

  return (
    <GlobalContext.Provider 
    value={{ showBookModal,
     handleCloseBookModal, 
     handleShowBookModal,
     handleReset,
     isHuman,
     setIsHuman,
     captcha,
     captchaInput, 
     setCaptchaInput
     }}>
      {children}
    </GlobalContext.Provider>
  );
};
