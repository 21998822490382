import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import homedata from '../../data/homedata';

export default function FooterAccordionUsage() {
    const [expanded, setExpanded] = React.useState(0);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };
    return (
        <div class="row">
            <div class="col-lg-6">
                {
                    homedata.foofterdata.slice(0, 5).map((data,i)=>
                        <Accordion 
                    key={data.id} 
                    expanded={expanded === data.id} 
                    onChange={handleChange(data.id)} 
                    sx={{marginTop:"20px"}}
                        >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <div class="card-header" id="headingOne">
                            <h4 class="mb-0">

                                {data.title}
                            </h4>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                       {data.description}
                    </AccordionDetails>
                </Accordion>
                    )
                }
                
            </div>

            <div class="col-lg-6">
                {
                    homedata.foofterdata.slice(5, 10).map((data,i)=>
                        <Accordion 
                    key={data.id} 
                    expanded={expanded === data.id} 
                    onChange={handleChange(data.id)} 
                    sx={{marginTop:"20px"}}
                        >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <div class="card-header" id="headingOne">
                            <h4 class="mb-0">

                                {data.title}
                            </h4>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                       {data.description}
                    </AccordionDetails>
                </Accordion>
                    )
                }
                
            </div>


        </div>
    );
}
