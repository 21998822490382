import React from 'react'
import animationData from '../../assets/images/a1q9CHOnua.json';
import Lottie from 'react-lottie';
import Modal from "@mui/material/Modal";
import { Button } from 'react-bootstrap';



const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
export const SuccessModal = (props) => {
    const {onClose,open,title} = props;
  return (
    <Modal open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
>
    <div className="modal-content"
    style={{
      'overflow': "hidden",
      'overflowY': "scroll",
      'scrollbarWidth': "none",
      height:"50vh",
   }}
    >
        <span className="close" onClick={onClose}>&times;</span>
    {/* <Lottie 
options={defaultOptions}
height={400}
width={400}
/>

        <h2> {title} </h2> */}
        <div class="container">
          {/* */}
            <div className='thanks-container'>
              {/* <div className='header-thank'> */}
                <img className="thank-logo" src="../image/img/logo_no_bg.png" alt="logo"/>
                <h3>Thank you for writing to us!</h3>
                <p>We’ve received your message and will get back to you as soon as possible. Our team is here to assist you with all your inquiries.Do we need the map?Subscribe us for news & offers
                </p>

              {/* </div> */}
            </div>

          </div>
        <Button onClick={onClose} class="pay-now-btn-booking button-primary ">Close</Button>
    </div>
</Modal>  )
}
