import React, { Children, useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { BookNowModal } from '../Components/common/BookNowModal'
import { GlobalContext } from '../contexts/GlobalState'
import MenuIcon from '@mui/icons-material/Menu';
import SideNavDrawer from '../Components/common/SideNavBar'
import homedata from '../data/homedata'

export const NavBar = () => {
   const { handleShowBookModal, showBookModal } = useContext(GlobalContext)

   const [open, setOpen] = React.useState(false);

   const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
   };

   return (
      <>
         <header id="masthead" className="site-header header-primary">
            {/* <!-- header html start --> */}
            <div className="top-header">
               {/* <div className="container">
                  <div className="row">
                     <div className="col-lg-8 d-none d-lg-block">
                        <div className="header-contact-info">
                           <ul>
                              <li>
                                 <Link to="#"><i className="fas fa-phone-alt"></i> +91 8199050506</Link>
                              </li>
                              <li>
                                 <Link to="mailto:info@Travel.com"><i className="fas fa-envelope"></i> info@xclusiveoman.com</Link>
                              </li>
                              <li>
                                 <i className="fas fa-map-marker-alt"></i> 128-A, D-Mall, NSP, Delhi-110034
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div className="col-lg-4 d-flex justify-content-lg-end justify-content-between">
                        <div className="header-social social-links">
                           <ul>
                              <li><Link to="#"><i className="fab fa-facebook-f" aria-hidden="true"></i></Link></li>
                              <li><Link to="#"><i className="fab fa-twitter" aria-hidden="true"></i></Link></li>
                              <li><Link to="#"><i className="fab fa-instagram" aria-hidden="true"></i></Link></li>
                              <li><Link to="#"><i className="fab fa-linkedin" aria-hidden="true"></i></Link></li>
                           </ul>
                        </div>
                        <div className="header-search-icon">
                           <button className="search-icon">
                              <i className="fas fa-search"></i>
                           </button>
                        </div>
                     </div>
                  </div>
               </div>  */}
            </div>
            <div className="bottom-header">
               <div className="container d-flex justify-content-between align-items-center">
                  <div className="site-identity">
                     <h1 className="site-title">

                        <Link to="/" className="main-navigation d-none d-lg-block">
                                <img className="white-logo" src="../image/img/logo_no_bg.png" alt="logo" />
                                <img className="black-logo" src="../image/img/logo_no_bg.png" alt="logo" />
                            </Link>
                        <div className="main-navigation d-lg-none d-sm-block">
                           <MenuIcon
                              sx={{ cursor: 'pointer' }}
                              onClick={toggleDrawer(true)}
                           />
                        </div>
                     </h1>
                  </div>
                  <div className="main-navigation d-none d-lg-block">
                     <nav id="navigation" className="navigation">
                        <ul>
                           {
                              homedata.nav_data.map((nav) =>
                                 <li className="menu-item-has-children">
                                    <Link 
                                    style={{textDecoration:"none"}}
                                     to={nav.link}>{nav.title}</Link>
                                 </li>
                              )
                           }
                           {/* <li className="menu-item-has-children">
                              <Link to="#">Dashboard</Link>
                              <ul>
                                 <li>
                                    <Link to="admin/dashboard.html">Dashboard</Link>
                                 </li>
                                 <li className="menu-item-has-children">
                                    <Link to="#">User</Link>
                                    <ul>
                                       <li>
                                          <Link to="admin/user.html">User List</Link>
                                       </li>
                                       <li>
                                          <Link to="admin/user-edit.html">User Edit</Link>
                                       </li>
                                       <li>
                                          <Link to="admin/new-user.html">New User</Link>
                                       </li>
                                    </ul>
                                 </li>
                                 <li>
                                    <Link to="admin/db-booking.html">Booking</Link>
                                 </li>
                                 <li className="menu-item-has-children">
                                    <Link to="admin/db-package.html">Package</Link>
                                    <ul>
                                       <li>
                                          <Link to="admin/db-package-active.html">Package Active</Link>
                                       </li>
                                       <li>
                                          <Link to="admin/db-package-pending.html">Package Pending</Link>
                                       </li>
                                       <li>
                                          <Link to="admin/db-package-expired.html">Package Expired</Link>
                                       </li>
                                    </ul>
                                 </li>
                                 <li>
                                    <Link to="admin/db-comment.html">Comments</Link>
                                 </li>
                                 <li>
                                    <Link to="admin/db-wishlist.html">Wishlist</Link>
                                 </li>
                                 <li>
                                    <Link to="admin/login.html">Login</Link>
                                 </li>
                                 <li>
                                    <Link to="admin/forgot.html">Forget Password</Link>
                                 </li>
                              </ul>
                           </li> */}
                        </ul>
                     </nav>
                  </div>


                  <div className='hed-in'>
                     <div className="header-btn">
                        <Button className="button-primary  bookTripBtn" onClick={handleShowBookModal}>BOOK NOW</Button>
                     </div>
                     {/* <div className="header-btn">
                        <Link to="/login" className="button-primary bookTripBtn" >
                        LOG IN             
                        <Button className="button-primary  bookTripBtn">
                        
                        </Button>
                      </Link>
                     </div> */}

                  </div>



                  {/* <!-- Pop-up Form -->
                                <!-- Booking Modal --> */}



               </div>
            </div>
            <div className="mobile-menu-container"></div>
         </header>
         <BookNowModal />
         <SideNavDrawer
            toggleDrawer={toggleDrawer}
            open={open}
            data={homedata.nav_data}
         />
      </>
      // <div className="home">

      // {/* <div id="page" className="full-page"> */}

      // {/* </div> */}
      // {/* </div> */}
   )
}
