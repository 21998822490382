import React, { useEffect, useState } from 'react'
import packages from '../data/package_data'
import { Cards } from '../Components/Packages/Cards';
import { Outlet } from 'react-router-dom';
import { Layout } from '../Layout/Layout';
import packagesApi from '../services/packagesServices';

export const Packages = () => {

  const [packages, setPackages] = useState([])

  const getPackages = async () => {
    try {
      const response = await packagesApi.getAllPackages();
      console.log(response.data.data);
      setPackages(response.data.data);
    }
    catch (error) {
      console.log(error);
    }

  }

  useEffect(() => {
    getPackages();
  }, [])



  return (
    <Layout title={"Packages"}>
      <>
        <main id="content" className="site-main">
          <section className="inner-banner-wrap">
            <div className="inner-baner-container"
              style={{ backgroundImage: "url(image/img/background-image.jpg)"}}
            >
              <div className="container">
                <div className="inner-banner-content">
                  <h1 className="inner-title">Packages</h1>
                </div>
              </div>
            </div>
            <div className="inner-shape"></div>
          </section>




          <section className="package-offer-wrap">
            <div className="special-section">
              <div className="container">
                <div className="special-inner">
                  <div className="row">
                    {/* {
                  packages.packageData?.map((data,index)=>  <Cards
                  {...data}
                  id={index+1}
                  />)
                 } */}
                    {
                      packages.map((data, index) => <Cards
                        {...data}
                      />
                      )
                    }


                  </div>


                </div>
              </div>
            </div>
          </section>
        </main>
        {/* <Outlet /> */}

      </>
    </Layout>
  )
}

