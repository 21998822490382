import React, { useContext, useEffect, useState } from "react";
import { Crousal } from "../Components/Home/Crousal";
import { GlobalContext } from "../contexts/GlobalState";
import { Enquire } from "../Components/Home/Enquire";
import FooterAccordionUsage from "../Components/Home/FooterAccordian";
import { PackageCard } from "../Components/Home/PackageCard";
import packages from "../data/package_data";
import { Layout } from "../Layout/Layout";
import packagesApi from "../services/packagesServices";
import { FlagshipPackageCard } from "../Components/Home/FlagshipPackageCard";

export const Home = () => {
  const { handleShowBookModal } = useContext(GlobalContext);
  const [bestPackages,setBastPackages] = useState([])

const getPackagesForHome = async()=>{
  try{
    const response = await packagesApi.packageForHome();
    const data = response.data.data;
    console.log(data);
    setBastPackages(data);
  }
  catch(e){
    console.error(e)
  }
}

useEffect(() => {
  getPackagesForHome()
},[])

  console.log("home page");
  return (
    <Layout title={"home"}>
    <>
      <main id="content" class="site-main">
        {/*{/*Home slider html start */}
        <section class="home-slider-section">
          <div class="home-slider">
            <div class="home-banner-items">
              <div class="banner-inner-wrap">
                <iframe
                  src="https://player.vimeo.com/video/1011262944?autoplay=1&muted=1&loop=1&background=1"
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    width: "100vw",
                    height: "100vh",
                    transform: "translate(-50%, -50%) scale(1.5)",
                    border: "none",
                  }}
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  title="Oman Edit."
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>

              <div class="banner-content-wrap">
                <div class="container">
                  <div class="banner-content text-center">
                    {/*<h2 style={{" color: white;">Get upto 50% off</h2> */}
                    <h2 class="banner-title">
                      {" "}
                      Xclusive Oman Holiday Packages{" "}
                    </h2>
                    <h3 style={{ color: "white" }}>
                    Starting at INR <b
                    style={{color:"#BA9524"}}
                    >24,999/- </b>only
                    </h3>
                    <h3  style={{ color: "white",}}>Unforgettable Experiences Await!</h3>
                    {/* <input
                      type="submit"
                      name="travel-search"
                      class="bookTripBtn button-primary"
                      value="ENQUIRE NOW"
                      onClick={handleShowBookModal}
                    /> */}
                  </div>
                </div>
              </div>
              {/*{/*<div class="overlay"></div> */}
            </div>
          </div>
        </section>
        {/*{/*slider html start */}
        {/*Home search field html start */}
       <Enquire/>
        {/*{/*search search field html end */}
        {/*Home packages section html start */}
        <section class="package-section" id="package-section">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-lg-8">
                  <h5 class="dash-style">EXPLORE GREAT PLACES</h5>
                  <h2>PREMIUM PACKAGES</h2>
                  <p>
                    Explore our handpicked travel packages, offering
                    unforgettable experiences at unbeatable prices. Whether it’s
                    a luxurious getaway or an adventurous escape, there’s
                    something for everyone.
                  </p>
                </div>
              </div>
            </div>

            <div class="package-inner">

            {/* Premium Packages */}

             <FlagshipPackageCard 
             handleShowBookModal={handleShowBookModal}
             />


              <div class="row">
{
  bestPackages?.map((data)=><PackageCard
  {...data}
  />
)
}
               
              </div>
              {/*{/*<div class="btn-wrap text-center">
                        <a href="#" class="button-primary">VIEW ALL PACKAGES</a>
                     </div>  */}
            </div>

          </div>
        </section>
        <section class="destination-section">
          <div class="container">
            <div class="section-heading">
              <div class="row align-items-end">
                <div class="col-lg-7">
                  <h5 class="dash-style">POPULAR DESTINATION</h5>
                  <h2>MUST-SEE DESTINATIONS</h2>
                </div>
                <div class="col-lg-5">
                  <div class="section-disc">
                    Discover the Enchantment of Oman, where ancient traditions
                    meet stunning landscapes, offers golden deserts, lush wadis,
                    and historic souks. From Muscat's vibrant markets to
                    Musandam's dramatic fjords, experience the magic of Oman.
                  </div>
                </div>
              </div>
            </div>
            <div class="destination-inner destination-three-column">
              <div class="row">
                <div class="col-lg-7">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="desti-item overlay-desti-item">
                        <figure class="desti-image">
                          <img src="image/img/Muscat_topNotch.jpg" alt="" />
                        </figure>
                        <div class="meta-cat bg-meta-cat">
                          <a href="#">50% off</a>
                        </div>
                        <div class="desti-content">
                          <h3>
                            <a href="package-detail.html">MUSCAT</a>
                          </h3>
                          <div class="rating-start" title="Rated 5 out of 4">
                            <span style={{ width: "93%" }}></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="desti-item overlay-desti-item">
                        <figure class="desti-image">
                          <img src="image/img/nizwa_topNotch.jpg" alt="" />
                        </figure>
                        <div class="meta-cat bg-meta-cat">
                          <a href="#">Hot Deal</a>
                        </div>
                        <div class="desti-content">
                          <h3>
                            <a href="#">Nizwa & Jebel Akhdar</a>
                          </h3>
                          <div class="rating-start" title="Rated 5 out of 5">
                            <span style={{ width: "100%" }}></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="row">
                    <div class="col-md-6 col-xl-12">
                      <div class="desti-item overlay-desti-item">
                        <figure class="desti-image">
                          <img src="image/img/dolphin_topNotch.jpg" alt="" />
                        </figure>
                        <div class="meta-cat bg-meta-cat">
                          <a href="#">Best Deal</a>
                        </div>
                        <div class="desti-content">
                          <h3>
                            <a href="#">Snorkelling and Dolphin Watching</a>
                          </h3>
                          <div class="rating-start" title="Rated 5 out of 5">
                            <span style={{ width: "100%" }}></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-xl-12">
                      <div class="desti-item overlay-desti-item">
                        <figure class="desti-image">
                          <img src="image/img/wadi_topNotch.jpg" alt="" />
                        </figure>
                        <div class="meta-cat bg-meta-cat">
                          <a href="#">Top pick</a>
                        </div>
                        <div class="desti-content">
                          <h3>
                            <a href="#">Wadi Shab & Wahiba sands</a>
                          </h3>
                          <div class="rating-start" title="Rated 5 out of 4">
                            <span style={{ width: "60%" }}></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-wrap text-center">
                {/*{/*<a href="#" class="button-primary">MORE DESTINATION</a> */}
              </div>
            </div>
          </div>
        </section>
        {/*packages html end */}
        {/*Home callback section html start */}
        <section class="callback-section" id="callback-section">
          <div class="container" id="about-us">
            <div class="row no-gutters align-items-center">
              <div class="col-lg-5">
                <div
                  class="callback-img"
                  style={{
                    backgroundImage: "url(image/img/image-5.jpg)",
                    // backgroundSize: "cover", // Optional: to cover the entire div
                    // backgroundPosition: "center", // Optional: center the image
                    // height: "100vh", // Adjust height as needed
                    // width: "100%", // Adjust width as needed
                  }}
                >
                  <div class="video-button">
                    {/*<a id="video-container" data-video-id="IUN664s7N-c">
                                 <i class="fas fa-play"></i>
                              </a> */}
                  </div>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="callback-inner">
                  <div class="section-heading section-heading-white">
                    {/*<h5 class="dash-style">CALLBACK FOR MORE</h5> */}
                    <h2>Explore the Magic of Oman with Xclusive Oman</h2>
                    <p>
                      Xclusive Oman is a specialized travel service under the
                      umbrella of EGS Group, Delhi. With years of experience in
                      the travel industry, we have honed our expertise in
                      curating exclusive tours that highlight the cultural
                      richness, natural beauty, and thrilling adventures that
                      Oman offers.
                    </p>
                  </div>
                  <div class="callback-counter-wrap">
                    <div class="counter-item">
                      <div class="counter-icon">
                        <img src="assets/images/icon1.png" alt="" />
                      </div>
                      <div class="counter-content">
                        <span class="counter-no">
                          Expertly curated itineraries
                        </span>
                        {/*<span class="counter-text">
                                       Satisfied Clients
                                    </span> */}
                      </div>
                    </div>
                    <div class="counter-item">
                      <div class="counter-icon">
                        <img src="assets/images/icon2.png" alt="" />
                      </div>
                      <div class="counter-content">
                        <span class="counter-no">
                          Professional & Friendly Guides
                        </span>
                        {/*<span class="counter-text">
                                       Satisfied Clients
                                    </span> */}
                      </div>
                    </div>
                    <div class="counter-item">
                      <div class="counter-icon">
                        <img src="assets/images/icon3.png" alt="" />
                      </div>
                      <div class="counter-content">
                        <span class="counter-no">
                          Comprehensive travel support
                        </span>
                        {/*<span class="counter-text">
                                       Satisfied Clients
                                    </span> */}
                      </div>
                    </div>
                    <div class="counter-item">
                      <div class="counter-icon">
                        <img src="assets/images/icon4.png" alt="" />
                      </div>
                      <div class="counter-content">
                        <span class="counter-no">
                          Xclusive unique experiences
                        </span>
                        {/*<span class="counter-text">
                                       Satisfied Clients
                                    </span> */}
                      </div>
                    </div>
                  </div>
                  <div class="support-area">
                    <div class="support-icon">
                      <img src="assets/images/icon5.png" alt="" />
                    </div>
                    <div class="support-content">
                      <h4>Our 24/7 Emergency Phone Services</h4>
                      <h3>
                        <a href="#">Call: +91 9540111207</a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Home testimonial section html start */}
        <div
          class="testimonial-section"
          style={{
            backgroundImage: "url(image/img/testimonials_bg.jpg)",
            // backgroundSize: "cover", // Optional: to cover the entire div
            // backgroundPosition: "center", // Optional: center the image
            // height: "100vh", // Adjust height as needed
            // width: "100%", // Adjust width as needed
          }}
        >
          <div class="container">
            <Crousal />
          </div>
        </div>
        {/*{/*testimonial html end */}
        {/*Home contact details section html start */}
        {/*<section class="contact-section">
               <div class="container">
                  <div class="row">
                     <div class="col-lg-4">
                        <div class="contact-img" style={{"background-image: url(image/img/image-6.jpg);">
                        </div>
                     </div>
                     <div class="col-lg-8">
                        <div class="contact-details-wrap">
                           <div class="row">
                              <div class="col-sm-4">
                                 <div class="contact-details">
                                    <div class="contact-icon">
                                       <img src="assets/images/icon12.png" alt="" />
                                    </div>
                                    <ul>
                                       <li>
                                          <a href="#">info@xclusiveoman.com</a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div class="col-sm-4">
                                 <div class="contact-details">
                                    <div class="contact-icon">
                                       <img src="assets/images/icon13.png" alt="" />
                                    </div>
                                    <ul>
                                       <li>
                                          <a href="#">+91 8199050506</a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div class="col-sm-4">
                                 <div class="contact-details">
                                    <div class="contact-icon">
                                       <img src="assets/images/icon14.png" alt="" />
                                    </div>
                                    <ul>
                                       <li>
                                          128-A, D-Mall, NSP, Delhi-110034
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="contact-btn-wrap">
                           <h3>LET'S JOIN US FOR MORE UPDATE !!</h3>
                           <a href="#" class="button-primary">LEARN MORE</a>
                        </div>
                     </div>
                  </div>
               </div>
            </section> */}
        {/*{/* contact details html end */}

        <div class="faq-page-section" id="faq">
          <div class="container">
            <div class="faq-page-container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="faq-content-wrap">
                    <div class="section-heading">
                      <h5 class="dash-style">ANY QUESTIONS</h5>
                      <h2>FREQUENTLY ASKED QUESTIONS</h2>
                      {/*{/*<p>Aperiam sociosqu urna praesent, tristique, corrupti condimentum asperiores platea ipsum ad arcu. Nostrud. Esse? Aut nostrum, ornare quas provident laoreet nesciunt odio voluptates etiam, omnis.</p> */}
                    </div>
                  </div>
                </div>
              </div>
              {/*{/*2x2 Grid for FAQs */}
              <div class="row" >
                <FooterAccordionUsage/>
              </div>
              {/*<h3 class="faq-end">We hope the above cover the most common questions. If not, please call or just send us an email. </h3> */}
            </div>
          </div>
        </div>
      </main>
    </>
    </Layout>
  );
};


