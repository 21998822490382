import React, { useState } from 'react'
import { Layout } from '../Layout/Layout'
import homedata from '../data/homedata'
import { ContactUsForm } from '../Components/contact_us/contact_us.form';

export const Contact = () => {
  const {contact_info} = homedata;
 

  return (
    <Layout title={"Contact Us"}>
    <>
      <main id="content" class="site-main">
        <section class="inner-banner-wrap">
          <div
            class="inner-baner-container"
            style={{ backgroundImage: "url(image/img/contact-us.png)" }}
          >
            <div class="container">
              <div class="inner-banner-content">
                <h1 class="inner-title">Contact Us</h1>
              </div>
            </div>
          </div>
          <div class="inner-shape"></div>
        </section>
        <div class="contact-page-section">
          <div class="contact-form-inner">
            <div class="container">
              <div class="row">
                <ContactUsForm
                />
                <div class="col-md-6">
                  <div class="contact-detail-wrap">
                    {/* <h3>Need help ?? Feel free to contact us !</h3>
                    <p>
                      Penatibus numquam? Non? Aliqua tempore est deserunt sequi
                      itaque, nascetur, consequuntur conubianigp, explicabo?
                      Primis convallis ullam. Egestas deserunt eius molestias
                      app incididunt.
                    </p>
                    <p>
                      Nostra doloribus blandit et semper ultrices, quibusdam
                      dignissimos! Netus recusandae, rerum cupidatat.
                      Perferendis aptent wisi.
                    </p> */}
                    <div class="details-list">
                      <ul>
                        <li>
                          <span class="icon">
                            <i class="fas fa-map-signs"></i>
                          </span>
                          <div class="details-content">
                            <h4>Corporate Office Address</h4>
                            <span>{contact_info.address}</span>
                          </div>
                        </li>
                        <li>
                          <span class="icon">
                            <i class="fas fa-envelope-open-text"></i>
                          </span>
                          <div class="details-content">
                            <h4>Email Address</h4>
                            <span>{contact_info?.email}</span>
                          </div>
                        </li>
                        <li>
                          <span class="icon">
                            <i class="fas fa-phone-volume"></i>
                          </span>
                          <div class="details-content">
                            <h4>Phone Number</h4>
                            <span
                            >
                              {contact_info?.phone}</span >
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="contct-social social-links">
                      <h3>Follow us on social media..</h3>
                      <ul>
                        <li>
                          <a href="https://www.facebook.com/p/xclusive-oman-61566384257373"
                         target='_blank'
                         ><i class="fab fa-facebook-f" aria-hidden="true"></i
                          ></a>
                        </li>                       
                        <li>
                          <a href="https://www.instagram.com/xclusive_oman/profilecard/?igsh=ZmVjbmJtYzA1eTVj"
                            target='_blank'
                          ><i class="fab fa-instagram" aria-hidden="true"></i
                          ></a>
                        </li>
                        <li>
                          <a href="#"
                          ><i class="fab fa-linkedin" aria-hidden="true"></i
                          ></a>
                        </li>
                        <li>
                          <a href="#"
                            ><i class="fab fa-twitter" aria-hidden="true"></i
                          ></a>
                        </li>
                        <li>
                          <a href="https://wa.me/9540111207"
                                target="_blank"

                            ><i class="fab fa-whatsapp" aria-hidden="true"></i
                          ></a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="map-section">
           <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d317838.95217734354!2d-0.27362819527326965!3d51.51107287614788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604c7c7eb9be3%3A0x3918653583725b56!2sRiverside%20Building%2C%20County%20Hall%2C%20Westminster%20Bridge%20Rd%2C%20London%20SE1%207JA%2C%20UK!5e0!3m2!1sen!2snp!4v1632135241093!5m2!1sen!2snp"
              height="400"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div> */}

          <div class="container"> 
          </div>
        </div>
      </main>
     
    </>
    </Layout>
  )
}



