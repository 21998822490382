import React, { useContext, useEffect } from "react";
import { Layout } from "../Layout/Layout";
import { GlobalContext } from "../contexts/GlobalState";

export const PrivacyPolicy = () => {
  const {handleCloseBookModal,showBookModal} = useContext(GlobalContext)



  useEffect(() => {
    handleCloseBookModal();
  },[])

  return (
    <Layout title={"Privacy Policy"}>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap">
          <div
            className="inner-baner-container visa-banner"
            style={{ backgroundImage: "url(image/img/privacy-banner.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h1 className="inner-title">Privacy Policy</h1>
              </div>
            </div>
          </div>
          <div className="inner-shape"></div>
        </section>
        <section className="about-section about-page-section">
          <div className="about-service-wrap">
            <div className="container">
              <div className="section-heading">
                <div className="row align-items-end">
                  <div className="col-lg-12">
                    <h5 className="dash-style">Privacy Policy</h5>
                    {/* <h2>Privacy Policy</h2> */}
                    <br />
                    <div>
                      <h3>Privacy Policy for Xclusive Oman</h3>
                    </div>
                    <p>
                      Xclusive Oman (the "Company" or "We") is a fully owned
                      subsidiary of EGS Group of Companies based in New Delhi,
                      India. We are committed to protecting your privacy and
                      safeguarding your personal information. This Privacy
                      Policy outlines how we collect, use, disclose, and protect
                      the information you provide to us through our website
                      https://xclusiveoman.com. By using our website, you agree
                      to the terms of this Privacy Policy.
                    </p>
                    <h4 className="information">
                      <span>1.</span> Information We Collect
                    </h4>
                    <h4>Personal information:</h4>
                    <p>
                      Personal information is information or an opinion, whether
                      true or not and whether recorded in a material form or
                      not, about an individual who is identified or reasonably
                      identifiable. When you contact us through the “Book Now”
                      and/or "Contact Us" form, we may collect personal
                      information, such as your name, email address, phone
                      number, and any other information you provide in your
                      communication.
                    </p>
                    <h4>Non-Personal Information:</h4>
                    <p>
                      We may collect non-personal information about your visit
                      to our website, such as the pages you viewed and time
                      spent on each page. This information helps us improve our
                      website and provide a better user experience.
                    </p>
                    <h4 className="information">
                      <span>2.</span> How We Use Your Information:
                    </h4>
                    <p>
                      We may use the information we collect for the following
                      purposes:
                    </p>
                    <ul>
                      <li>
                        <strong>To Respond to Your Inquiries: </strong> We use
                        your contact details to respond to your questions,
                        provide information about our services, or address any
                        concerns you may have.
                      </li>
                      <li>
                        <strong>To Improve Our Services: </strong>We analyse
                        non-personal information to enhance the functionality
                        and content of our website.
                      </li>
                      <li>
                        <strong>To Communicate with You: </strong>With your
                        consent, we may send you updates, newsletters, or
                        promotional information about our services. You will
                        always have an option to discontinue such communication
                        at any time.
                      </li>
                    </ul>
                    <p>
                      We may also use the information for research and analytics
                      purposes.
                    </p>
                    <h4 className="information">
                      <span>3.</span> Information Sharing and Disclosure
                    </h4>
                    <p>
                      We do not sell, trade, or otherwise transfer your personal
                      information to third parties, except in the following
                      cases:
                    </p>
                    <ul>
                      <li>
                        <strong>Service Providers: </strong>We may share your
                        information with trusted third-party service providers
                        who help us conduct our business, or provide services to
                        you, under the condition that they agree to keep your
                        information confidential.
                      </li>
                      <li>
                        <strong>Legal Requirements: </strong>We may disclose
                        your information if required to do so by law or in
                        response to valid requests by a law enforcement agency
                        or public authorities.
                      </li>
                    </ul>
                    <h4 className="information">
                      <span>4.</span> Data Security
                    </h4>
                    <p>
                      We use appropriate technical and organizational measures
                      to protect your personal information from unauthorized
                      access, use, or disclosure. We also require our service
                      providers to maintain appropriate security measures.
                    </p>
                    <h4 className="information">
                      <span>5.</span>Cookies
                    </h4>
                    <p>
                      Cookies are small data files stored on your browser. Our
                      website may use cookies and similar technologies to
                      enhance your experience, such as remembering your
                      preferences or recognizing you on repeat visits. You can
                      choose to disable cookies in your browser settings, but
                      this may affect your experience on our site.
                    </p>
                    <h4 className="information">
                      <span>6.</span>Retention of Information
                    </h4>
                    <p>
                      We retain your personal information for as long as
                      necessary to provide our services or as required by law.
                    </p>
                    <h4 className="information">
                      <span>7.</span>Links to Other Websites
                    </h4>
                    <p>
                      Our website may contain links to third-party websites. We
                      are not responsible for the privacy practices or content
                      of these external sites. We encourage you to review the
                      privacy policies of any websites you visit through our
                      links.
                    </p>
                    <h4 className="information">
                      <span>8.</span> Changes to This Privacy Policy
                    </h4>
                    <p>
                      We may update this Privacy Policy from time to time to
                      reflect changes in our practices or applicable laws. We
                      encourage you to review this page regularly for any
                      updates (Additions/deletions/edits). Your continued use of
                      our website after any changes to this policy will
                      constitute your acknowledgment of those changes.{" "}
                    </p>
                    <h4 className="information">
                      <span>9.</span> Your Rights
                    </h4>
                    You have the right to:
                    <ul>
                      <li>
                        Access and request a copy of the personal information we
                        hold about you.
                      </li>
                      <li>
                        Request the correction of any inaccurate information.
                      </li>
                      <li>
                        Request the deletion of your personal information,
                        subject to legal requirements.
                      </li>
                      <li>
                        Withdraw your consent to receive communications from us
                        at any time.
                      </li>
                    </ul>
                    <p>
                      To exercise these rights, please contact us at
                      info@xclusiveoman.com.
                    </p>
                    <h4 className="information">
                      <span>10.</span> Contact Us
                    </h4>
                    <p>
                      If you have any questions or concerns about our privacy
                      practices, please contact us at
                    </p>
                    <div>
                    Xclusive Oman
                    <br />
                    Email: info@xclusiveoman.com
                    <br />
                    Phone: +91 8929290107

                    </div><br/>
                    
                
                      <p>
                        This Privacy Policy is effective as of 8th November
                        2024.
                      </p>
                      <p>
                        Thank you for trusting Xclusive Oman with your
                        information. We are committed to ensuring your privacy
                        and providing a safe and enjoyable online experience.
                      </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};
