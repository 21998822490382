import React from "react";
import { Layout } from "../Layout/Layout";
import { SuccessModal } from "../Components/common/SuccessModal";

export const About = () => {
  return (
    <Layout title={"About us"}>
    <>
      <main id="content" className="site-main">
        <section className="inner-banner-wrap">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: "url(image/img/background-image-2.jpg)" }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h1 className="inner-title">About Xclusive Oman</h1>
              </div>
            </div>
          </div>
          <div className="inner-shape"></div>
        </section>

        <section className="about-section about-page-section">
          <div className="about-service-wrap">
            <div className="container">
              <div className="section-heading">
                <div className="row align-items-end">
                  <div className="col-lg-6">
                    <h5 className="dash-style">INTRODUCTION OF US</h5>
                    <h2>Redefining Premium Experiences in Oman</h2>
                    <div className="about-introduction-image col-lg-12">
                    <img className="col-lg-12"  src="image/img/nizwa_topNotch.jpg" alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="section-disc">
                      <h3>
                        Xclusive Oman
                      </h3>
                      <p>
                      At Xclusive Oman, we believe travel should be transformative, luxurious, and unforgettable. As a specialized travel service under EGS Group, we bring Oman’s cultural richness, stunning landscapes, and adventurous essence to life through meticulously curated, high-end holiday experiences. Our mission is to elevate Oman as a premier travel destination, offering superior journeys that captivate and inspire.
                      </p><br/>
                      <h3>Our Values</h3>
                      <p>
                      Our values are rooted in excellence, authenticity, and customer-centered service. Each experience we offer is infused with an unwavering commitment to quality, creativity, and the highest standards of comfort.
                      </p><br/>
                      <h3>Our Team</h3>
                      <p>
                      Founded by industry experts with years of experience both in Oman and India, Xclusive Oman is driven by a passion for redefining luxury travel. Since our inception, we’ve consistently delivered memorable experiences for travellers from India, Southeast Asia, Europe and beyond. Backed by a seasoned team, we seamlessly manage every requirement for Oman, whether it’s securing a tourist visa, planning a family holiday, exploring new business opportunities,  or organizing a MICE event or destination wedding—all under one roof at Xclusive Oman.
                      Xclusive Oman is your ultimate gateway to all requirements related to Oman. We warmly invite you to experience the Sultanate of Oman firsthand.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="about-service-container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="about-service">
                      <div className="about-service-icon">
                        <img src="assets/images/icon15.png" alt="" />
                      </div>
                      <div className="about-service-content">
                        <h4>AFFORDABLE PRICE</h4>
                        <p>
                          Discover Oman's best,with luxury experiences at affordable rates.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="about-service">
                      <div className="about-service-icon">
                        <img src="assets/images/icon16.png" alt="" />
                      </div>
                      <div className="about-service-content">
                        <h4>BEST DESTINATION</h4>
                        <p>
                          Visit top destinations,each promising unforgettable sights and experiences.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="about-service">
                      <div className="about-service-icon">
                        <img src="assets/images/icon17.png" alt="" />
                      </div>
                      <div className="about-service-content">
                        <h4>PERSONAL SERVICE</h4>
                        <p>
                          Enjoy personal service from start to finish,making every moment special.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
                className="about-video-wrap"
                style={{
                  backgroundImage:
                    "url(image/img/tourists-enjoy-ride-horses-cappadocia-turkey.jpg)",
                }}
              >
                <div className="video-button">
                  <a id="video-container" data-video-id="IUN664s7N-c">
                    <i className="fas fa-play"></i>
                  </a>
                </div>
              </div> */}
            </div>
          </div>

          {/* <div className="client-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="section-heading text-center">
                    <h5 className="dash-style">OUR ASSOCAITES</h5>
                    <h2>PARTNER'S AND CLIENTS</h2>
                    <p>
                      Mollit voluptatem perspiciatis convallis elementum
                      corporis quo veritatis aliquid blandit, blandit torquent,
                      odit placeat. Adipiscing repudiandae eius cursus? Nostrum
                      magnis maxime curae placeat.
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
          </div> */}

          <div
            className="fullwidth-callback"
            style={{ backgroundImage: "url(image/img/image-4.jpg)" }}
          >
            <div className="container">
              <div className="section-heading section-heading-white text-center">
              {/* <h2 className="travel" 
                    // style={{color:"#ffff"}}
                    >EXPLORE. DISCOVER. REMEMBER US!</h2> */}
                
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    {/* <h5 className="dash-style">CALLBACK FOR MORE</h5> */}
                    <h2 className="travel" 

                    >EXPLORE. DISCOVER. REMEMBER US!</h2>
                    <p>
                      {/* Mollit voluptatem perspiciatis convallis elementum
                      corporis quo veritatis aliquid blandit, blandit torquent,
                      odit placeat. Adipiscing repudiandae eius cursus? Nostrum
                      magnis maxime curae placeat. */}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="callback-counter-wrap">
                <div className="counter-item">
                  <div className="counter-item-inner">
                    <div className="counter-icon">
                      <img src="assets/images/icon1.png" alt="" />
                    </div>
                    <div className="counter-content">
                      <span className="counter-no">
                        <span className="counter">800</span>+
                      </span>
                      <span className="counter-text">Clients</span>
                    </div>
                  </div>
                </div>
                <div className="counter-item">
                  <div className="counter-item-inner">
                    <div className="counter-icon">
                      <img src="assets/images/icon2.png" alt="" />
                    </div>
                    <div className="counter-content">
                      <span className="counter-no">
                        <span className="counter">5</span>+
                      </span>
                      <span className="counter-text">Years</span>
                    </div>
                  </div>
                </div>
                <div className="counter-item">
                  <div className="counter-item-inner">
                    <div className="counter-icon">
                      <img src="assets/images/icon3.png" alt="" />
                    </div>
                    <div className="counter-content">
                      <span className="counter-no">
                        <span className="counter">40</span>+
                      </span>
                      <span className="counter-text">Direct Hotels</span>
                    </div>
                  </div>
                </div>
                <div className="counter-item">
                  <div className="counter-item-inner">
                    <div className="counter-icon">
                      <img src="assets/images/icon4.png" alt="" />
                    </div>
                    <div className="counter-content">
                      <span className="counter-no">
                        <span className="counter">15</span>+
                      </span>
                      <span className="counter-text">Day Tours</span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </main>
      
    </>
    </Layout>

  );
};
