import React from 'react'
import homedata from '../data/homedata'
import { Link } from 'react-router-dom';

export const Footer = () => {
   const { contact_info } = homedata;
   return (
      <>
         <footer id="colophon" className="site-footer footer-primary">
            <div className="top-footer" id="contact-us">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-3 col-md-6">
                        <aside className="widget widget_text">
                           <h3 className="widget-title">
                              Xclusive Oman
                           </h3>
                           <div className="textwidget widget-text">
                              At Xclusive Oman, we bring you the finest travel experiences to the enchanting land of Oman.
                           </div>
                           {/* <div className="award-img">
                         <a href="#"><img src="assets/images/logo6.png" alt=""></a>
                         <a href="#"><img src="assets/images/logo2.png" alt=""></a>
                      </div>  */}
                        </aside>
                     </div>
                     <div className="col-lg-3 col-md-6">
                        <aside className="widget widget_text">
                           <h3 className="widget-title">CONTACT Us</h3>
                           <div className="textwidget widget-text">
                              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.  */}
                              <ul>
                                 <li>
                                    <a href={`tel:${contact_info?.phone}`}>
                                       <i className="fas fa-phone-alt"></i>
                                       {contact_info?.phone}
                                    </a>
                                 </li>
                                 <li>
                                    <a href={`mailto:${contact_info?.email}`}>
                                       <i className="fas fa-envelope"></i>
                                       {contact_info?.email}
                                    </a>

                                 </li>
                                 <li>
                                    <i className="fas fa-map-marker-alt"></i>
                                    {contact_info?.address}
                                 </li>
                              </ul>
                           </div>
                        </aside>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="widget widget_newslatter">
                           <h3 className="widget-title">SUBSCRIBE US FOR NEWS & OFFERS</h3>
                           <div className="widget-text">
                              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.  */}
                           </div>
                           <form className="newslatter-form">
                              <input type="email" name="s" placeholder="Your Email" />
                              <input type="submit" className="button-primary" name="s" value="SUBSCRIBE NOW" />
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="buttom-footer">
               <div className="container">
                  <div className="row align-items-center">
                     <div className="col-md-5">
                        <div className="footer-menu">
                           <ul>
                              <li>
                                 <Link to="/privacy-policy">Privacy Policy</Link>
                              </li>
                              <li>
                                 <a href="#">Term & Condition</a>
                              </li>
                              <li>
                                 <Link to="/?#faq">FAQ</Link>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div className="col-md-2 text-center">
                        {/* <div className="footer-logo">
                      <a href="#"><img src="assets/images/travele-logo.png" alt=""></a>
                   </div>  */}
                     </div>
                     <div className="col-md-5">
                        <div className="copy-right text-right">Copyright © 2024 Xclusive Oman</div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
         <a id="backTotop" href="#" className="to-top-icon">
            <i className="fas fa-chevron-up"></i>
         </a>
         {/* custom search field html  */}
         <div className="header-search-form">
            <div className="container">
               <div className="header-search-container">
                  <form className="search-form" role="search" method="get" >
                     <input type="text" name="s" placeholder="Enter your text..." />
                  </form>
                  <a href="#" className="search-close">
                     <i className="fas fa-times"></i>
                  </a>
               </div>
            </div>
         </div>
         {/* header html end  */}
      </>

   )
}
