import logo from './logo.svg';
import './App.css';
import { Home } from './pages/home';
import { Layout } from './Layout/Layout';
import { About } from './pages/About';
import Router from './routes';
import { Packages } from './pages/Packages';

function App() {
  return (
    <>
    {/* <Layout title={"home"}> */}
      {/* <About/> */}
    {/* <Home/> */}
    {/* <Packages/> */}
    {/* <div style={{height:"100vh", background:"red"}}></div> */}
    <Router/>
    {/* </Layout> */}
    </>
  );
}

export default App;
