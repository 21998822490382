import React from 'react'
import { Link } from 'react-router-dom'
import { Layout } from '../Layout/Layout'

export const PageNotFound = () => {
  return (
	<Layout title={"page not found"}>

    <div>
         
  
  <section class="bg-light">
	<div class="container-fluid">
		<div class="row row-cols-1 justify-content-center py-5">
			<div class="col-xxl-7 mb-4">
				<div class="lc-block">
                <div id="animation-container" lang="en" role="img" class="main" aria-label="Lottie animation">
      <div id="animation" class="animation" style={{"background":"transparent;"}}>
        <img src='image/img/404.svg' alt="404"/>
      </div>
        {/* <!--?lit$8549464945$--> */}
      {/* <!--?lit$8549464945$--> */}
    </div>
					{/* <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
					<lottie-player src="https://assets9.lottiefiles.com/packages/lf20_u1xuufn3.json" class="mx-auto" background="transparent" speed="1" loop="" autoplay=""></lottie-player> */}
				</div>
			</div>
			<div class="col text-center">
				<div class="lc-block">
					<div class="lc-block mb-4">
						<div editable="rich">
							<p class="rfs-11 fw-light"> The page you are looking for was moved, removed or might never existed.</p>
						</div>
					</div>
					<div class="lc-block">
						<Link class="btn btn-lg btn-primary" to="/" role="button">Back to homepage</Link>
					</div>
				</div>
			</div>
		</div>

	</div>
</section>
 
      <p class="py-5 small text-center text-muted">  Powered by <a href="https://library.livecanvas.com/">LiveCanvas HTML Templates for Bootstrap</a>  
    </p>
  

    </div>
	</Layout>
  )
}
