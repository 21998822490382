import React, { useContext } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
// import Modal from 'react-bootstrap/Modal';
import Box from "@mui/material/Box";
// import Button from '@mui/material/Button';
import Modal from "@mui/material/Modal";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import enquireApi from '../../services/enquireService';


import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import { GlobalContext } from "../../contexts/GlobalState";
import { SuccessModal } from "./SuccessModal";
import { Margin } from "@mui/icons-material";
import SimpleCaptchaForm from "./SimpleCaptchaForm";
import { Link } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    outline: "none",
    borderRadius: "10px",
    
  };

export const BookNowModal = (props) => {
    // const {show,handleShow,setShow} = props;
    
    const {handleCloseBookModal,showBookModal,handleReset,isHuman,setIsHuman} = useContext(GlobalContext)
    const [openSuccess, setOpenSuccess] = useState(false);
    const [load, setLoad] = useState(false);


    const handleCloseSucess = () => {setOpenSuccess(false);}
//   const [show, setShow] = useState(true);
//   const handleShow = () => setShow(true);

  // return (
  //   <>
  //     {/* <Button variant="primary" onClick={handleShow}>
  //           Launch demo modal
  //         </Button> */}

  //     {/* <Modal show={show} onHide={handleClose}>
  //           <Modal.Header closeButton>
  //             <Modal.Title>Book Your Trip</Modal.Title>
  //           </Modal.Header>
  //           <Modal.Body>

  //           </Modal.Body>
  //           <Modal.Footer>
  //             <Button variant="secondary" onClick={handleClose}>
  //               Close
  //             </Button>
  //             <Button variant="primary" onClick={handleClose}>
  //               Save Changes
  //             </Button>
  //           </Modal.Footer>
  //         </Modal> */}

  //     <div>
  //       <Modal
  //         open={showBookModal}
  //         onClose={handleCloseBookModal}
  //         // style={{border: 'none',}}
  //         aria-labelledby="modal-modal-title"
  //         aria-describedby="modal-modal-description"
  //       >
  //         <Box sx={style}>
  //           {/* <div id="bookingModal" className="modal-booking"> */}
  //             {/* <div className="modal-content-booking"> */}
  //             <div style={{display:"flex",justifyContent:"space-between"}}>
  //             <h2>Book Your Trip</h2>
  //             <span className="close-booking" onClick={handleCloseBookModal}>&times;</span>
  //             </div>
               

  //               <form>
  //                 <div className="form-row-booking">
  //                   <div className="input-group-booking">
  //                     <label>Name*</label>
  //                     <input
  //                       type="text"
  //                       id="name-booking"
  //                       name="name"
  //                       placeholder="Your name"
  //                       required
  //                     />
  //                   </div>

  //                   <div className="input-group-booking">
  //                     <label>Email*</label>
  //                     <input
  //                       type="email"
  //                       id="email-booking"
  //                       name="email"
  //                       placeholder="Your email"
  //                       required
  //                     />
  //                   </div>
  //                 </div>

  //                 <div className="form-row-booking">
  //                   <div className="input-group-booking">
  //                     <label for="adults-booking">Number of Adults*</label>
  //                     <input
  //                       type="number"
  //                       id="adults-booking"
  //                       name="adults"
  //                       placeholder="Number of adults"
  //                       min="1"
  //                       required
  //                     />
  //                   </div>

  //                   <div className="input-group-booking" style={{marginTop:"20px"}}>
  //                     <label for="children-booking">Number of Children</label>
  //                     <input
  //                       type="number"
  //                       id="children-booking"
  //                       name="children"
  //                       placeholder="Number of children"
  //                       min="0"
  //                     />
  //                   </div>
  //                 </div>

  //                 <div className="form-row-booking">
  //                   <div className="input-group-booking">
  //                     <label for="children_age-booking">Age of Children</label>
  //                     <input
  //                       type="text"
  //                       id="children_age-booking"
  //                       name="children_age"
  //                       placeholder="e.g., 4, 8"
  //                     />
  //                   </div>
  //                 </div>

  //                 <button type="submit" className="pay-now-btn-booking">
  //                   Pay Now
  //                 </button>
  //               </form>
  //             {/* </div> */}
  //           {/* </div> */}
  //         </Box>
  //       </Modal>
  //     </div>
  //   </>
  // );



  const today = new Date().toISOString().split('T')[0];


    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const AddNewClientSchema = Yup.object().shape({
        // reference: Yup.string().required("Reference is required"),
        name: Yup.string().required("Name is required"),
        phone_number: Yup.string().min(today, 'Check-in date cannot be in the past')
            .required("Mobile number is required")
            .matches(phoneRegExp, "Mobile number is not valid")
            .min(10, "Mobile number is not valid")
            .max(10, "Mobile number not valid"),
        email: Yup.string().required(" Email is required"),
        requirements: Yup.string().required(" Requirements is required"),
        // no_of_guist: Yup.number().required(" No of guist is required").min(1, "Number of guests must be greater than 0"),
        // checkin_date: Yup.date().required("Check in date is required"),
        // check_out_date: Yup.date().required("Check out date is required"),
        // destination: Yup.string().required("Destination is required"),
        // deprature_cityi: Yup.string().required("Deprature Cityi is required"),
        // agreement: Yup.boolean().oneOf([true], 'You must accept the agreement').required("Agreement is required"),
    });

    const formik = useFormik({
        initialValues: {
            "name": "",
            "email": "",
            "phone_number": "",
            "requirements": "Tourist Visa",
            // "fathers_name": "",
            // "no_of_guist": 0,
            // "checkin_date": "",
            // "check_out_date": "",
            // "destination": "",
            // "deprature_cityi": "",
            // "agreement": false,
        },
        validationSchema: AddNewClientSchema,
        onSubmit: async (values, { resetForm, setErrors }) => {
            // setLoading(true);
            if (!isHuman) {
                alert("Please solve the CAPTCHA correctly");
                return;
            }
            setLoad(true)
            console.log(values);
            try {
                console.log(values, "all values");
                const response = await enquireApi.CreateEnquire(values);
                console.log(response.data);
                resetForm();
                handleCloseBookModal()
                setLoad(false)
                setIsHuman(false);
                handleReset();
                setOpenSuccess(true)
            } catch (error) {
                // resetForm();
                setErrors({ afterSubmit: error.message });
            }
        },
    });

    const {
        values,
        touched,
        errors,
        handleSubmit,
        getFieldProps,
        setFieldValue,
    } = formik;
    console.log(touched, "----->", errors, "=====>", values)
  return (
    <>
      <Modal open={showBookModal}
    onClose={handleCloseBookModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
>
    <div className="modal-content" style={{
       'overflow': "hidden",
       'overflowY': "scroll",
       'scrollbarWidth': "none",
       height:"60vh",
    }}>
        <span className="close" onClick={handleCloseBookModal}>&times;</span>
        <h2>Let's hear from you! </h2>
        <div>
            {Object.keys(errors).map((key) => (
                <li key={key} className='error-text'>{errors[key]}</li>
            ))}
        </div>
        <form>
            {/* <div className="form-row">
                <div className="popUp-input-group input-group">
                    <label for="destination">DESTINATION*</label>
                    <input
                        type="text"
                        id="destination"
                        name="destination"
                        value={values.destination}
                        placeholder="Oman"
                        required
                        {...getFieldProps('destination')}
                    />
                </div>

                <div className="popUp-input-group input-group">
                    <label for="departure_city">DEPARTURE CITY*</label>
                    <input
                        type="text"
                        id="departure_city"
                        name="departure_city"
                        value={values.deprature_cityi}
                        placeholder="New Delhi"
                        required
                        {...getFieldProps('deprature_cityi')}
                    />
                </div>
            </div> */}

            <div className="form-row">
                <div className="popUp-input-group input-group">
                    <label for="name">Name*</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={values.name}
                        placeholder="Your name"
                        required
                        {...getFieldProps('name')}
                    />
                </div>

                <div className="popUp-input-group input-group">
                    <label for="phone">Phone*</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={values.phone_number}
                        placeholder="Your phone number"
                        required
                        {...getFieldProps('phone_number')}

                    />
                </div>
            </div>

            <div className="form-row">
                <div className="popUp-input-group input-group">
                    <label for="email">Email id*</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={values.email}
                        placeholder="Your email"
                        required
                        {...getFieldProps('email')}

                    />
                </div>

                <div className="popUp-input-group input-group">
                            <label> Requirement*</label>
                            {/* <input
                                // type=""
                                name="requirements"
                                value={values.requirements}
                                placeholder="Enter Requiremen"
                                required
                                {...getFieldProps('requirements')}
                            /> */}
                            <select
                            {...getFieldProps('requirements')}
                            >
                                <option value={''}>Select Requirement</option>
                                <option value={'Tourist Visa'}>Tourist Visa</option>
                                <option value={'Holiday Package'}>Holiday Package</option>
                                <option value={'Hotel Booking'}>Hotel Booking</option>
                                <option value={'other'}>Other</option>
                            </select>
                            {touched.requirements && errors.requirements ? (
                                <div className="error-text">{errors.requirements}</div>
                            ) : null}
                        </div>

                

                {/* <div className="popUp-input-group input-group">
                <label> No. of Guests* </label>
                <input
                    type="number"
                    name="no_of_guist"
                    value={values.no_of_guist}
                    placeholder="No. of People"
                    {...getFieldProps('no_of_guist')}
                    required
                />
                {touched.no_of_guist && errors.no_of_guist ? (
                    <div className="error-text">{errors.no_of_guist}</div>
                ) : null}
                </div> */}
            </div>


            {/* <div className="form-row">
                <div className="popUp-input-group input-group">
                <label> Checkin Date* </label>
                <input
                    className="input-date-picker"
                    type="date"
                    name="checkin_date"
                    value={values.checkin_date}
                    placeholder="MM / DD / YY"
                    autoComplete="off"
                    {...getFieldProps('checkin_date')}
                    required
                    min={new Date().toISOString().split('T')[0]} // Restricts past dates
                />
                {touched.checkin_date && errors.checkin_date ? (
                    <div className="error-text">{errors.checkin_date}</div>
                ) : null}
                </div>

                <div className="popUp-input-group input-group">
                <label> Checkout Date* </label>
                <input
                    className="input-date-picker"
                    type="date"
                    name="check_out_date"
                    value={values.check_out_date}
                    placeholder="MM / DD / YY"
                    autoComplete="off"
                    {...getFieldProps('check_out_date')}
                    required
                    min={values.checkin_date || new Date().toISOString().split('T')[0]} // Restricts before check-in date
                />
                {touched.check_out_date && errors.check_out_date ? (
                    <div className="error-text">{errors.check_out_date}</div>
                ) : null}
                </div>
            </div> */}


            
           

            <div className="form-row">
                <div className="checkbox-container">
                    <input
                        type="checkbox"
                        id="agreement-checkbox"
                        name="agreement"
                        value={values.agreement}
                        required
                        onChange={(e) => {
                            console.log("Checked", e.target.checked)
                            setFieldValue('agreement', e.target.checked)
                        }}
                    />
                    <label
                        for="agreement-checkbox"
                        className="agreement-label"
                    >
                        I have read and agree to the{" "}
                        <Link to="/privacy-policy">User Agreement</Link> &{" "}
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </label>
                </div>
            </div>
            <SimpleCaptchaForm
                    />
            {!load && <Button type="submit"
                className="button-primary"
                id="enquireBtn"
                style={{ background:`${!isHuman ? '#229b92' : null}`}}
                // value="Get a Callback"
                onClick={handleSubmit}
                disabled={load || !isHuman}
            >Get a Callback</Button>}
            {/* Get a Callback */}
            {/* </input> */}
        </form>
    </div>
</Modal>
<SuccessModal
title={"Enquire Add Succesfull"}
open={openSuccess}
onClose={handleCloseSucess}
/>
    </>
  
  )
};
