import { api_route } from "../Api-Config/config";
import axiosInstance from "./axios";




const getAllBlogs = async()=>{
    return axiosInstance.get(api_route+"/blogs");
}

const Blogsdetails = async (id)=>{
    return axiosInstance.get(api_route+"/blogs/"+id);
}

const blogsApi = {getAllBlogs,Blogsdetails}
export default blogsApi;



