import { api_route } from "../Api-Config/config";
import axiosInstance from "./axios";




const getAllPackages = async()=>{
    return axiosInstance.get(api_route+"/packages");
}
const getFlagshipPackages = async()=>{
    return axiosInstance.get(api_route+"/flagship_packages");
}
const packageDetails = async (id)=>{
    return axiosInstance.get(api_route+"/packages/"+id);
}
const packageForHome = async (id)=>{
    return axiosInstance.get(api_route+"/packagesforHome");
}

const packagesApi = {getAllPackages,getFlagshipPackages,packageDetails,packageForHome}
export default packagesApi;