import React from 'react'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 600,
   bgcolor: "background.paper",
   border: "1px solid #000",
   boxShadow: 24,
   p: 4,
   outline: "none",
   borderRadius: "10px",
   height:"90vh",
    overflow: "hidden",
  overflowY: "scroll",
  scrollbarWidth: "none", // Hide scrollbar in Firefox

 };
export const VisaBookModal = (props) => {
   const { handleClose, open } = props;
   return (
      <>
         {/* <div id="ApplyVisaModal" class="modal-booking"> */}
         <Modal open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ border: 'none' }}
         >
                      <Box sx={style}>
                      {/* <div class="modal-content-booking"> */}
               <span class="close-booking close" onClick={handleClose}>&times;</span>
               <h2>Apply VISA</h2>
               <form>
                  <div class="form-row-booking">
                     <div class="input-group-booking">
                        <label for="name-booking">Passport Type*</label>
                        <input type="text" id="name-booking" name="name" placeholder="Passport type" required />
                     </div>

                     <div class="input-group-booking">
                        <label for="email-booking">Nationality*</label>
                        <input type="text" id="nationality" name="nationality" placeholder="Nationality" required />
                     </div>
                  </div>

                  <div class="form-row-booking">
                     <div class="input-group-booking">
                        <label for="adults-booking">Port Of Arrival*</label>
                        <input type="text" id="port" name="adults" placeholder="Port Of Arrival" min="1" required />
                     </div>

                     <div class="input-group-booking">
                        <label for="children-booking">Date Of Birth*</label>
                        <input type="text" id="children-booking" name="children" placeholder="date of birth" min="0" />
                     </div>
                  </div>

                  <div class="form-row-booking">
                     <div class="input-group-booking">
                        <label for="adults-booking">Email ID*</label>
                        <input type="email" id="email" name="email" placeholder="email id" min="1" required />
                     </div>

                     <div class="input-group-booking">
                        <label for="children-booking">Re-Enter Email ID*</label>
                        <input type="email" id="children-booking" name="children" placeholder="re-enter email id" min="0" />
                     </div>
                  </div>

                  <div class="form-row-booking">
                     <div class="input-group-booking">
                        <label for="adults-booking">Expected Date Of Arrival*</label>
                        <input type="text" id="port" name="adults" placeholder="expected date of arrival" min="1" required />
                     </div>

                     <div class="input-group-booking">
                        <label for="children-booking">Please Enter Above Text*</label>
                        <input type="text" id="children-booking" name="children" placeholder="Please enter above text" min="0" />
                     </div>
                  </div>

                  <div class="service">
                     <div>
                        Visa Service*
                     </div>

                     <div>
                        <div>
                           <input type="checkbox" /> <span> eTOURIST VISA</span>
                        </div>
                        <div>
                           <input type="checkbox" /> <span> eMEDICAL VISA</span>
                        </div>
                        <div>
                           <input type="checkbox" /> <span> eBUSINESS VISA</span>
                        </div>
                        <div>
                           <input type="checkbox" /> <span> eCONFERENCE VISA</span>
                        </div>
                        <div>
                           <input type="checkbox" /> <span> eMEDICAL ATTENDANT VISA</span>
                        </div>
                     </div>
                  </div>

                  <div>
                     <input type="checkbox" /> <span> I have read the instruction, I have all the required documents format.</span>
                  </div>

                  <button type="submit" class="pay-now-btn-booking">Pay Now</button>
               </form>
            {/* </div> */}
                      </Box>
            
         </Modal>

         {/* </div> */}
      </>
   )
}
