

// export const api_route = 'http://localhost:8001/api'; // localhost


// export const api_route = 'http://192.241.137.17:8000/api'; // production

// export const api_route = "https://xclusive-oman-backend.onrender.com/api";   //development

export const api_route = "https://backend.xclusiveoman.com/api" // production
// export const api_route = "https://xclusivomansql-9djfrl2m.b4a.run/api";   //development

