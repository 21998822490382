import React, { useEffect, useState } from 'react'
import { Layout } from '../Layout/Layout'
import { useParams } from 'react-router-dom';
import blogsApi from '../services/blogsServices';

export const Blogsdetails = () => {
   const { id } = useParams(); // Get the 'id' from the URL

   const [details,setDetails] = useState();
   const getDetails = async(id)=>{
      try{
         const response = await blogsApi.Blogsdetails(id);
         const data = response.data.data;
         setDetails(data)
      }
      catch(e){
         console.log(e);
      }
   }
   useEffect(()=>{
      if(id){
         getDetails(id)
      }
   },[id])
  return (
   <Layout title={"Blog Details"}>
    <>
    <main id="content" class="site-main">
            <section class="inner-banner-wrap">
               <div class="inner-baner-container" style={{backgroundImage: `url(${details?.featured_image})`}}>
                  <div class="container">
                     <div class="inner-banner-content">
                        <h1 class="inner-title">{details?.blog_title}</h1>
                     </div>
                  </div>
               </div>
               <div class="inner-shape"></div>
            </section>
      
            <div class="single-post-section">
               <div class="single-post-inner">
                  <div class="container">
                     <div class="row">
                        <div class="col-lg-8 primary right-sidebar">
                      
                           <figure class="feature-image">
                              <img src={details?.featured_image} alt=""/>
                           </figure>
                           <article class="single-content-wrap">
                              <h3>{details?.blog_title}</h3>
                              <p>{details?.main_content}</p>
                              <blockquote>
                                 <p>{details?.introduction_summary }</p>
                              </blockquote>
                              <p>{details?.related_blogs}</p>
                           </article>
                           <div class="meta-wrap">
                              <div class="tag-links">
                                 
                                 <a href="#">{details?.tags}</a>,
                                 {/* <a href="#">hiking</a>,
                                 <a href="#">Travel Dairies</a>,
                                 <a href="#">Travelling</a>,
                                 <a href="#">Trekking</a> */}
                              </div>
                           </div>
                           <div class="post-socail-wrap">
                              <div class="social-icon-wrap">
                                 <div class="social-icon social-facebook">
                                    <a href="#">
                                       <i class="fab fa-facebook-f"></i>
                                       <span>Facebook</span>
                                    </a>
                                 </div>
                                 <div class="social-icon social-google">
                                    <a href="#">
                                       <i class="fab fa-instagram"></i>
                                       <span>Instagram</span>
                                    </a>
                                 </div>
                                 <div class="social-icon social-pinterest">
                                    <a href="#">
                                       <i class="fab fa-pinterest"></i>
                                       <span>Pinterest</span>
                                    </a>
                                 </div>
                                 <div class="social-icon social-linkedin">
                                    <a href="#">
                                       <i class="fab fa-linkedin"></i>
                                       <span>Linkedin</span>
                                    </a>
                                 </div>
                                 <div class="social-icon social-twitter">
                                    <a href="#">
                                       <i class="fab fa-twitter"></i>
                                       <span>Twitter</span>
                                    </a>
                                 </div>
                              </div>
                           </div>
                          
                           <div class="comment-area">
                                       
                           </div>
                    
                        </div>
                        <div class="col-lg-4 secondary">
                           <div class="sidebar">
                              <aside class="widget author_widget">
                                 <h3 class="widget-title">ABOUT AUTHOR</h3>
                                 <div class="widget-content text-center"> 
                                    <div class="profile"> 
                                       <figure class="avatar"> 
                                          <a href="#"> 
                                             <img src="../image/img/oman 4.jpg" alt=""/> 
                                          </a> 
                                       </figure> 
                                       <div class="text-content"> 
                                          <div class="name-title"> 
                                             <h3> 
                                                <a href="https://demo.bosathemes.com/bosa/photography/james-watson/">{details?.author_name}</a>
                                             </h3> 
                                          </div> 
                                          {/* <p>Accumsan? Aliquet nobis doloremque, aliqua? Inceptos voluptatem, duis tempore optio quae animi viverra distinctio cumque vivamus, earum congue, anim velit</p>  */}
                                       </div> 
                                       <div class="socialgroup"> 
                                          <ul> 
                                             <li> <a target="_blank" href="#"> <i class="fab fa-facebook"></i> </a> </li> 
                                             <li> <a target="_blank" href="#"> <i class="fab fa-whatsapp"></i> </a> </li> 
                                             <li> <a target="_blank" href="#"> <i class="fab fa-twitter"></i> </a> </li> 
                                             <li> <a target="_blank" href="#"> <i class="fab fa-instagram"></i> </a> </li> 
                                             <li> <a target="_blank" href="#"> <i class="fab fa-linkedin"></i> </a> </li> 
                                          </ul> 
                                       </div> 
                                    </div> 
                                 </div>
                              </aside>
                              
                              <aside class="widget widget_social">
                                 <h3 class="widget-title">Social share</h3>
                                 <div class="social-icon-wrap">
                                    <div class="social-icon social-facebook">
                                       <a href="#">
                                          <i class="fab fa-facebook-f"></i>
                                          <span>Facebook</span>
                                       </a>
                                    </div>
                                    <div class="social-icon social-pinterest">
                                       <a href="#">
                                          <i class="fab fa-pinterest"></i>
                                          <span>Pinterest</span>
                                       </a>
                                    </div>
                                    <div class="social-icon social-whatsapp">
                                       <a href="#">
                                          <i class="fab fa-whatsapp"></i>
                                          <span>WhatsApp</span>
                                       </a>
                                    </div>
                                    <div class="social-icon social-linkedin">
                                       <a href="#">
                                          <i class="fab fa-linkedin"></i>
                                          <span>Linkedin</span>
                                       </a>
                                    </div>
                                    <div class="social-icon social-twitter">
                                       <a href="#">
                                          <i class="fab fa-twitter"></i>
                                          <span>Twitter</span>
                                       </a>
                                    </div>
                                    <div class="social-icon social-google">
                                       <a href="#">
                                          <i class="fab fa-instagram"></i>
                                          <span>Instagram</span>
                                       </a>
                                    </div>
                                 </div>
                              </aside>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </main>
    </>
   </Layout>
  )
}
