import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';

export default function SubmitAlert({ message, type = "success", render }) {
    const [visible, setVisible] = useState(render);

    useEffect(() => {
        if (render) {
            setVisible(true); // Show the alert
            const timer = setTimeout(() => {
                setVisible(false); // Hide after 10 seconds
            }, 10000); // 10 seconds in milliseconds

            return () => clearTimeout(timer); // Cleanup timer on component unmount
        }
    }, [render]); // Dependency on render

    if (!visible) return null;

    return (
        <Alert severity={type}>
            {message}
        </Alert>
    );
}
