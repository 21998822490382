import React, { useContext } from 'react'
import { Button } from "react-bootstrap";
import { GlobalContext } from '../../contexts/GlobalState';

export const VisaApplyForm = () => {
    const { handleShowBookModal } = useContext(GlobalContext);

  return (
    <div className="col-lg-4">
    <div className="sidebar">
      <div className="widget-bg booking-form-wrap">
        <div>
          <h3>Visa Process</h3>
          <p>
            If you apply for Visa with valid documents today,
            <span>expect to receive it by 28 dec</span>
          </p>
        </div>
        <div className="document">
          <div className="box">1</div>
          <div>
            <h4>Upload Documents</h4>
            <p>Upload a soft copy and apply your visa</p>
          </div>
        </div>

        <div className="document">
          <div className="box">2</div>
          <div>
            <h4>Pay Online</h4>
            <p>Complete your paymode of your choice.</p>
          </div>
        </div>

        <div className="document">
          <div className="box">3</div>
          <div>
            <h4>Get Your Visa</h4>
            <p>Keep your visa status and get visa on mail.</p>
          </div>
        </div>
      </div>

      <div className="col-sm-12">
        <div className="form-group submit-btn">
          <Button onClick={handleShowBookModal} className="button-primary  bookTripBtn" id="applyVisabutton" type="submit" name="submit" >Apply for Visa</Button>
        </div>
      </div>

      {/* <div>
        <input
        type="submit"
        name="travel-search"
        class="bookTripBtn button-primary"
        value="Apply for Visa"                  
        />
        </div> */}

    </div>
  </div>
  )
}
