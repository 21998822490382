import React, { useContext } from 'react'
import { GlobalContext } from '../../contexts/GlobalState';
import { Link } from 'react-router-dom';
import formatNumberWithCommas from '../../Constant/functions/currencyMaker';

export const PackageCard = (props) => {
  const { handleShowBookModal } = useContext(GlobalContext);

const {id,
  package_name,
  thumbnail,
  price,
  duration,
  maximum_group_size,
  destination,
  discount_price,
  reviewsCount,
  ratingPercentage,
  tooltipText,
  bookNowLink,
  enquireNowLink} = props;
  
  return (
    <div class="col-lg-4 col-md-6">
  <div class="package-wrap">
    <figure class="feature-image">
      <Link to={`/package-details?id=${id}`}>
        <img src={thumbnail} alt=""  style={{height:"356px"}}/>
      </Link>
    </figure>
    <div class="package-price">
      <h6>
        <span>₹ {discount_price && formatNumberWithCommas(discount_price)} </span> / per person
      </h6>
    </div>
    <div class="package-content-wrap">
      <div class="package-meta text-center">
        <ul>
          <li>
            <i class="far fa-clock"></i>
            {duration}
          </li>
          <li>
            <i class="fas fa-user-friends"></i>
            People: {maximum_group_size}
          </li>
          <li>
            <i class="fas fa-map-marker-alt"></i>
            {destination}
          </li>
        </ul>
      </div>
      <div class="package-content">
        <h3>
          <Link to={`/package-details?id=${id}`}>{package_name}</Link>
        </h3>
        <div class="review-area">
          {/* <span class="review-text">({reviewsCount ? reviewsCount : 10} reviews)</span>
          <div class="rating-start" title="Rated 5 out of 5">
            <span style={{ width: `${ratingPercentage}%` }}></span>
          </div> */}
        </div>
        {/*{/*<p>Example Text</p> */}
        <div class="btn-wrap">
          <a onClick={handleShowBookModal} class="button-text width-6 bookTripBtn">
            Book Now<i class="fas fa-arrow-right"></i>
          </a>
          <a onClick={handleShowBookModal} class="button-text width-6 EnquireNowBtn">
            Enquire Now<i class="fas fa-phone rotate-icon"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}







