import React from "react";
import { Link } from "react-router-dom";
import formatNumberWithCommas from "../../Constant/functions/currencyMaker";

export const Cards = (props) => {
    // const {city,title,price,discount,discount_price,img_url,id} = props;
    const {thumbnail,discount_value,discount_price,price, id ,package_name ,accommodation,sq_id } = props
  return (
    <>
      <div className="col-md-6 col-lg-4">
    {/* {id} */}
        <div className="special-item">
          <figure className="special-img">
            <img src={thumbnail} alt=""  
            style={{height:"357px"}}
            />
          </figure>
          <div className="badge-dis">
            <span>
              <strong>{discount_value}%</strong>
              off
            </span>
          </div>
          <div className="special-content">
            <div className="meta-cat">
              <Link to={"/package-details?id="+id}>{accommodation}</Link>
            </div>
            <h3>
              <Link to={`/package-details?id=${id}`}>{package_name}</Link>
            </h3>
            <div className="package-price">
              Price:
              <del> ₹ {formatNumberWithCommas(price)}</del>
              <ins>₹ {formatNumberWithCommas(discount_price)}</ins>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
