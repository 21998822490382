import React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export const Terms_condition = () => {
    return (
        <Accordion
            // key={data.id} 
            // expanded={expanded === data.id} 
            // onChange={handleChange(data.id)} 
            sx={{ marginBottom: "20px" }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <div class="card-header" id="headingOne">
                    <div>
                        <h4 className="visa-sub-head">
                            Terms & Conditions
                        </h4>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <h4 >
                        Terms and Conditions for Oman Visa Application
                        through Xclusive Oman
                    </h4>
                </div>
                <ul>
                    <li>
                        <strong>1. Non-Refundable Visa Fee:</strong> Once
                        paid, the visa fee is non-refundable, regardless
                        of whether the visa is approved, delayed, or
                        rejected.
                    </li>
                    <li>
                        <strong>2. Visa Approval:</strong> Approval of the
                        visa is solely at the discretion of Oman
                        immigration officials. Xclusive Oman does not
                        influence or controlthe approval process.
                    </li>
                    <li>
                        <strong>3. No Guarantee of Approval:</strong> We
                        cannot guarantee that all visa applications will
                        be approved. Each application is subject to Oman
                        immigration regulations.
                    </li>
                    <li>
                        <strong>4. Additional Documentation:</strong>{" "}
                        Xclusive Oman may request additional documents
                        based on immigration requirements. In cases where
                        visa applications cannot be processed due to
                        changes in immigration or visa rules, Xclusive
                        Oman will inform the applicant.
                    </li>
                    <li>
                        <strong>5. E-Visa Verification:</strong> All Oman
                        visas are issued as online e-visas. It is the
                        responsibility of the traveler to verify that all
                        information on the visa matches their passport
                        details. Any discrepancies should be reported to
                        Xclusive Oman staff immediately.
                    </li>
                    <li>
                        <strong>6. Visa Message or OK-to-Board:</strong>{" "}
                        Updating a visa message or adding an OK-to-Board
                        status may incur an additional fee or be included
                        with certain visas, as indicated on the website
                        and based on airline regulations.
                    </li>
                    <li>
                        <strong>7. Criminal Activities: </strong> Any
                        criminal activity committed by the traveler during
                        their visit to Oman will be handled by the Omani
                        police. Xclusive Oman is not liable for any legal
                        matters arising during the traveler’s stay.
                    </li>
                    <li>
                        <strong>8. Overstay or Legal Penalties:</strong>{" "}
                        If a traveler overstays or if they are imprisoned
                        due to legal issues, the traveler and/or their
                        guarantor will be solely responsible for any
                        penalties or liabilities according to Omani law.
                    </li>
                    <li>
                        <strong>9. Right to Amend Terms:</strong> Xclusive
                        Oman reserves the right to change these terms and
                        conditions at any time without prior notice.
                    </li>
                </ul>
            </AccordionDetails>
        </Accordion>
    )
}
