import React, { useEffect, useState } from 'react'
import packagesApi from '../../services/packagesServices';
import { Link } from 'react-router-dom';
import formatNumberWithCommas from '../../Constant/functions/currencyMaker';

export const FlagshipPackageCard = ({handleShowBookModal}) => {
    const [flagshipData,setFlagshipData] = useState({});
    const {id,thumbnail,package_name,discount_price,destination,duration,maximum_group_size,description,accommodation} = flagshipData;

const getFagshipData = async()=>{
    try{
        const response = await packagesApi.getFlagshipPackages();
        const data = response.data.data;
        setFlagshipData(data);
    }
    catch(error){
        console.log(error);
    }
}

    useEffect(()=>{
        getFagshipData();
    },[])

  return (
    <div class="card">
                <div class="image-container">
                  <Link to={id ? "/package-details?id="+flagshipData.id :"#"}>
                    <img src={thumbnail || "image/img/best-seller_bg.jpg"} alt="Oman" />
                  </Link>
                  <div class="best-seller">Best Seller</div>
                  <div class="price-tag package-price">
                    <h6>
                      <span>Rs. {formatNumberWithCommas( discount_price)} </span>/ per person
                    </h6>
                    <a
                      href="#"
                      onClick={handleShowBookModal}
                      class="button-text width-6 limited-offer EnquireNowBtn"
                    >
                      Enquire Now <i class="fas fa-phone rotate-icon"></i>
                    </a>
                  </div>
                  <div class="trip-info package-meta text-center">
                    <ul>
                      <li>
                        <i class="far fa-clock"></i>
                        {duration}
                      </li>
                      <li>
                        <i class="fas fa-user-friends"></i>
                        People: {maximum_group_size}
                      </li>
                      <li>
                        <i class="fas fa-map-marker-alt"></i>
                        {destination}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content  col-lg-12">
                  <h2>
                    {package_name} <span class="stars">★★★★★</span>
                  </h2>
                  <p class="details">
                    Includes Air Fare | Visa | {accommodation} Hotel | Airport Transfer |
                    Sightseeing | Shopping Experience
                  </p>
                  <p class="description">
                  {description}
                    <a onClick={handleShowBookModal} class="book-now bookTripBtn">
                      Book now.
                    </a>
                  </p>
                </div>
              </div>
  )
}
