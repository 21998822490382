import React from 'react'
import "./login.css"
import { Layout } from '../Layout/Layout'
export const Login = () => {
  return (
    <Layout title={"Log in"}>
    <>
    <main id="content" className="site-main">
            {/* <h2>Login Form</h2> */}
    <form 
    >
    <div className="imgcontainer">
    <img src="image/img/img_avatar2.png" alt="Avatar" className="avatar"/>
     </div>

    <div className="container">
    <label for="uname"><b>Username</b></label>
    <input type="text" placeholder="Enter Username" name="uname" required/>

    <label for="psw"><b>Password</b></label>
    <input type="password" placeholder="Enter Password" name="psw" required/>
        
    <button type="submit">Login</button>
    <label>
      <input type="checkbox" checked="checked" name="remember"/> Remember me
    </label>
    </div>
    <div className="container" style={{backgroundColor:"#f1f1f1"}}>
    <button type="button" className="cancelbtn">Cancel</button>
    <span className="psw">Forgot <a href="#">password?</a></span>
   </div>
   </form>
   </main>
    </>
    </Layout>
  )
}
