import React, { useContext, useState } from 'react'
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Modal from "@mui/material/Modal";
import enquireApi from '../../services/enquireService';
import { Button } from 'react-bootstrap';
import { SuccessModal } from '../common/SuccessModal';
import { GlobalContext } from '../../contexts/GlobalState';







export const Enquire = () => {
    const { handleShowBookModal } = useContext(GlobalContext);


    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);

    const [openSuccess, setOpenSuccess] = useState(false);


   const handleCloseSucess = () => {setOpenSuccess(false);}


    const [err, setErr] = useState({})
    const handleClose = () => {
        setOpen(false)
    }
    const today = new Date().toISOString().split('T')[0];


    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const AddNewClientSchema = Yup.object().shape({
        // reference: Yup.string().required("Reference is required"),
        name: Yup.string().required("Name is required"),
        phone_number: Yup.string().min(today, 'Check-in date cannot be in the past')
            .required("Mobile number is required")
            .matches(phoneRegExp, "Mobile number is not valid")
            .min(10, "Mobile number is not valid")
            .max(10, "Mobile number not valid"),
        email: Yup.string().required(" Email is required"),
        requirements: Yup.string().required(" Requirements is required"),
        // no_of_guist: Yup.number().required(" No of guist is required").min(1, "Number of guests must be greater than 0"),
        // checkin_date: Yup.date().required("Check in date is required"),
        // check_out_date: Yup.date().required("Check out date is required"),
        // destination: Yup.string().required("Destination is required"),
        // deprature_cityi: Yup.string().required("Deprature Cityi is required"),
        // agreement: Yup.boolean().oneOf([true], 'You must accept the agreement').required("Agreement is required"),
    });

    const formik = useFormik({
        initialValues: {
            "name": "",
            "email": "",
            "phone_number": "",
            "requirements": "Tourist Visa",
            // "fathers_name": "",
            // "no_of_guist": 0,
            // "checkin_date": "",
            // "check_out_date": "",
            // "destination": "",
            // "deprature_cityi": "",
            // "agreement": false,
        },
        validationSchema: AddNewClientSchema,
        onSubmit: async (values, { resetForm, setErrors }) => {
            // setLoading(true);
            setLoad(true)
            console.log(values);
            try {
                console.log(values, "all values");
                const response = await enquireApi.CreateEnquire(values);
                console.log(response.data);
                resetForm();
                setOpen(false)
                setLoad(false)
                setOpenSuccess(true)
            } catch (error) {
                resetForm();
                setErrors({ afterSubmit: error.message });
            }
        },
    });

    const {
        values,
        touched,
        errors,
        handleSubmit,
        getFieldProps,
        setFieldValue,
    } = formik;
    console.log(touched, "----->", errors, "=====>", values)
    return (
        <>
            <div className="trip-search-section shape-search-section">
                <div className="slider-shape"></div>
                <div className="container">
                    <div className="trip-search-inner white-bg d-flex">
                    {/* <div className="input-group">
                            <label> DESTINATION* </label>
                            <input
                                // type="text"
                                name="destination"
                                value={values.destination}
                                placeholder="Enter destination Address"
                                required
                                {...getFieldProps('destination')}
                            />
                            {touched.destination && errors.destination ? (
                                <div className="error-text">{errors.destination}</div>
                            ) : null}
                        </div> */}
            

                        <div className="input-group">
                            <label> Name* </label>
                            <input
                                type="name"
                                name="name"
                                value={values.name}
                                placeholder="Your Name "
                                required
                                {...getFieldProps('name')}
                            />
                            {touched.name && errors.name ? (
                                <div className="error-text">{errors.name}</div>
                            ) : null}
                        </div>
                        <div className="input-group">
                            <label> Email Address* </label>
                            <input
                                type="email"
                                // name="email"
                                value={values.email}
                                placeholder="Enter Email Address"
                                required
                                {...getFieldProps('email')}
                            />
                            {touched.email && errors.email ? (
                                <div className="error-text">{errors.email}</div>
                            ) : null}
                        </div>

                        <div className="input-group">
                            <label> Mobile Number* </label>
                            <input
                                type="tel"
                                name="phone_number"
                                value={values.phone_number}
                                placeholder="Enter Mobile Number"
                                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                required
                                {...getFieldProps('phone_number')}
                            />
                            {touched.phone_number && errors.phone_number ? (
                                <div className="error-text">{errors.phone_number}</div>
                            ) : null}
                        </div>

                        <div className="input-group">
                            <label> Requirement*</label>
                            {/* <input
                                // type=""
                                name="requirements"
                                value={values.requirements}
                                placeholder="Enter Requiremen"
                                required
                                {...getFieldProps('requirements')}
                            /> */}
                            <select
                            {...getFieldProps('requirements')}
                            >
                                <option value={''}>Select Requirement</option>
                                <option value={'Tourist Visa'}>Tourist Visa</option>
                                <option value={'Holiday Package'}>Holiday Package</option>
                                <option value={'Hotel Booking'}>Hotel Booking</option>
                                <option value={'other'}>Other</option>
                            </select>
                            {touched.requirements && errors.requirements ? (
                                <div className="error-text">{errors.requirements}</div>
                            ) : null}
                        </div>

                        {/* <div className="input-group">
                            <label>Requirement* </label>
                            <input
                                type="number"
                                name="no_of_guist"
                                value={values.no_of_guist}
                                placeholder="No. of People"
                                {...getFieldProps('no_of_guist')}
                                required
                            />
                            {touched.no_of_guist && errors.no_of_guist ? (
                                <div className="error-text">{errors.no_of_guist}</div>
                            ) : null}
                        </div> */}

                        {/* <div className="input-group width-col-3">
                            <label> Checkin Date* </label>
                            <input
                                className="input-date-picker"
                                type="date"
                                name="checkin_date"
                                value={values.checkin_date}
                                placeholder="MM / DD / YY"
                                autoComplete="off"
                                {...getFieldProps('checkin_date')}
                                required
                                min={new Date().toISOString().split('T')[0]} // Restricts past dates
                            />
                            {touched.checkin_date && errors.checkin_date ? (
                                <div className="error-text">{errors.checkin_date}</div>
                            ) : null}
                        </div>

                        <div className="input-group width-col-3">
                            <label> Checkout Date* </label>
                            <input
                                className="input-date-picker"
                                type="date"
                                name="check_out_date"
                                value={values.check_out_date}
                                placeholder="MM / DD / YY"
                                autoComplete="off"
                                {...getFieldProps('check_out_date')}
                                required
                                min={values.checkin_date || new Date().toISOString().split('T')[0]} // Restricts before check-in date
                            />
                            {touched.check_out_date && errors.check_out_date ? (
                                <div className="error-text">{errors.check_out_date}</div>
                            ) : null}
                        </div> */}

                        <div className="input-group width-col-3">
                            <label className="screen-reader-text"> Search </label>
                            {/* <input
                               
                            /> */}
                            <button type="submit"
                                className="button-primary"
                                id="enquireBtn"
                                onClick={handleSubmit}
                                // onClick={() => handleShowBookModal()}
                            >ENQUIRE NOW</button>
                        </div>
                    </div>

                </div>
            </div>

            {/* <div id="myModal" className="modal"> */}
            {/*{/*Modal content */}
            <Modal open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal-content">
                    <span className="close" onClick={handleClose}>&times;</span>
                    <h2>Get in touch with us </h2>
                    <div>
                        {Object.keys(errors).map((key) => (
                            <li key={key} className='error-text'>{errors[key]}</li>
                        ))}
                    </div>
                    <form>

                        <div className="form-row">
                            <div className="popUp-input-group input-group">
                                <label for="destination">DESTINATION*</label>
                                <input
                                    type="text"
                                    id="destination"
                                    name="destination"
                                    value={values.destination}
                                    placeholder="Oman"
                                    required
                                    {...getFieldProps('destination')}
                                />
                            </div>

                            <div className="popUp-input-group input-group">
                                <label for="departure_city">DEPARTURE CITY*</label>
                                <input
                                    type="text"
                                    id="departure_city"
                                    name="departure_city"
                                    value={values.deprature_cityi}
                                    placeholder="New Delhi"
                                    required
                                    {...getFieldProps('deprature_cityi')}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="popUp-input-group input-group">
                                <label for="name">Name*</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={values.name}
                                    placeholder="Your name"
                                    required
                                    {...getFieldProps('name')}
                                />
                            </div>

                            <div className="popUp-input-group input-group">
                                <label for="phone">PHONE*</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    value={values.phone_number}
                                    placeholder="Your phone number"
                                    required
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="popUp-input-group input-group">
                                <label for="email">EMAIL ID*</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={values.email}
                                    placeholder="Your email"
                                    required
                                    readOnly
                                />
                            </div>

                            <div className="popUp-input-group input-group">
                            <label> No. of Guests* </label>
                            <input
                                type="number"
                                name="no_of_guist"
                                value={values.no_of_guist}
                                placeholder="No. of People"
                                {...getFieldProps('no_of_guist')}
                                required
                            />
                            {touched.no_of_guist && errors.no_of_guist ? (
                                <div className="error-text">{errors.no_of_guist}</div>
                            ) : null}
                            </div>
                        </div>


                        <div className="form-row">
                            <div className="popUp-input-group input-group">
                            <label> Checkin Date* </label>
                            <input
                                className="input-date-picker"
                                type="date"
                                name="checkin_date"
                                value={values.checkin_date}
                                placeholder="MM / DD / YY"
                                autoComplete="off"
                                {...getFieldProps('checkin_date')}
                                required
                                min={new Date().toISOString().split('T')[0]} // Restricts past dates
                            />
                            {touched.checkin_date && errors.checkin_date ? (
                                <div className="error-text">{errors.checkin_date}</div>
                            ) : null}
                            </div>

                            <div className="popUp-input-group input-group">
                            <label> Checkout Date* </label>
                            <input
                                className="input-date-picker"
                                type="date"
                                name="check_out_date"
                                value={values.check_out_date}
                                placeholder="MM / DD / YY"
                                autoComplete="off"
                                {...getFieldProps('check_out_date')}
                                required
                                min={values.checkin_date || new Date().toISOString().split('T')[0]} // Restricts before check-in date
                            />
                            {touched.check_out_date && errors.check_out_date ? (
                                <div className="error-text">{errors.check_out_date}</div>
                            ) : null}
                            </div>
                        </div>


                        
                       

                        <div className="form-row">
                            <div className="checkbox-container">
                                <input
                                    type="checkbox"
                                    id="agreement-checkbox"
                                    name="agreement"
                                    value={values.agreement}
                                    required
                                    onChange={(e) => {
                                        console.log("Checked", e.target.checked)
                                        setFieldValue('agreement', e.target.checked)
                                    }}
                                />
                                <label
                                    for="agreement-checkbox"
                                    className="agreement-label"
                                >
                                    I have read and agree to the{" "}
                                    <a href="#">User Agreement</a> &{" "}
                                    <a href="#">Privacy Policy</a>
                                </label>
                            </div>
                        </div>

                        {!load && <Button type="submit"
                            className="button-primary"
                            id="enquireBtn"
                            // value="Get a Callback"
                            onClick={handleSubmit}
                            disabled={load}
                        >Get a Callback</Button>}
                        {/* Get a Callback */}
                        {/* </input> */}
                    </form>
                </div>
            </Modal>
<SuccessModal
title={"Enquire Add Succesfull"}
open={openSuccess}
onClose={handleCloseSucess}
/>
            {/* </div> */}
           
        </>
    )
}
