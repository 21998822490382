const foofterdata = [
    {
      id: 0,
      title: "What makes Oman a must travel destination for your next journey?",
      description: "Oman is a mesmerizing blend of amazing landmarks, enriched cultural heritage, and warm hospitality, making it one of the best travel destinations worth exploring. From the stunning dunes of the Wahiba Sands to the scenic views in Jabel Akhdar, Oman offers a diverse array of natural attractions all under one roof. The country's fascinating history, crystal-clear beaches, and thriving marine life are exceptional charms that create a paradise for tourists. Whether you're seeking a romantic getaway or a family vacation, Oman promises unforgettable experiences."
    },
    {
      id: 1,
      title: "What makes booking an Oman Holiday Package with Xclusive Oman the perfect choice for your next journey?",
      description: "Unlike others, Xclusive Oman focuses exclusively on Oman holiday packages. Booking your journey with us ensures a seamless and unforgettable travel experience. Our expertly crafted itineraries allow you to explore Oman’s top attractions, all while enjoying your flights and luxurious stay in premium hotels. Each activity is tailored to your preferences and budget, ensuring your journey is uniquely yours. Plus, our dedicated customer support team is always on hand to assist you. As a specialized travel service under the esteemed EGS Group in Delhi, Xclusive Oman curates specialized luxurious holiday experiences. With years of expertise in the travel industry, we deliver personalized explorations that captivate your senses and immerse you in the beauty and heritage of Oman."
    },
    {
      id: 2,
      title: "What is the best time to visit Oman?",
      description: "The ideal time to visit Oman is between October and April when the weather is cooler and more pleasant for outdoor activities."
    },
    {
      id: 3,
      title: "Do I need a visa to visit Oman?",
      description: "Indian require a visa to enter Oman. However, holders of a valid U.S. visa can benefit from a visa exemption. For your visa requirements, you are recommended to apply for an e-visa through Xclusive Oman to make your journey easy and seamless with minimum hassles!"
    },
    {
      id: 4,
      title: "What’s the average flight duration between India to Oman?",
      description: "The average flight duration for a non-stop flight from India to Muscat, Oman, typically ranges from 3 to 4 hours. This duration depends on your departure city. For instance, a flight of just 2 hours and 30 minutes takes you from Mumbai to Muscat, while flights from Delhi take around 3 hours and 20 minutes. Muscat is one of the closest international destinations that saves your flight time and money as well."
    },
    {
      id: 5,
      title: "What are the must-see attractions in Oman?",
      description: "Oman offers a breathtaking array of attractions. From the majestic Sultan Qaboos Grand Mosque to the rugged beauty of Jebel Akhdar, the serene waters of Wadi Shab, the historic forts of Nizwa, and the vast expanse of the Wahiba Sands, there's something for everyone. Explore ancient monuments, wander through bustling markets, or simply relax on the stunning natural beaches."
    },
    {
      id: 6,
      title: "What cultural souvenirs can I buy in Mutrah Souq, Muscat?",
      description: "Discover the authentic Omani items available at Mutrah Souq in Muscat, from silver jewellery and antiques to frankincense, spices and traditional handicrafts and make the most of your shopping experience."
    },
    {
      id: 7,
      title: "What language is spoken in Oman?",
      description: "The official language is Arabic, but English is widely spoken in tourist areas and major cities. People around you will appreciate if you make the effort to say hello ('marhaban'), Peace Be Upon You ('A-Salam Alaykom'), and thank you ('shukran') in Arabic."
    },
    {
      id: 8,
      title: "What is the local currency, and can I use credit cards?",
      description: "The currency is the Omani Rial (OMR). 1 Omani Rial is approx. equal to 2.60 US Dollars. Credit cards are accepted in many places, but it’s advisable to carry some cash for smaller vendors and markets."
    },
    {
      id: 9,
      title: "What type of clothes should be worn on the Oman trip?",
      description: "Dress modestly, especially in rural areas. Since Oman respects its cultural, both men and women must cover their knees and shoulders at all times. Women are also asked to cover their hair and faces in the religious places. It's important to respect local traditions and customs."
    },
    {
      id: 10,
      title: "Who can I contact in case of a problem?",
      description: "Your tour guide or our local representative will be delighted to assist you throughout your stay in Oman. Should you need further support, feel free to reach out to us via phone or email. You can find our communication details on the Contact Us page of this website. We're here to ensure you have an unforgettable experience!"
    },
    {
      id: 11,
      title: "How can I explore Oman's local cuisine?",
      description: "You can experience Omani cuisine by visiting traditional restaurants and local markets, where you can try popular dishes like Majboos, Shuwa, and Halwa."
    }
];

const nav_data = [
  {
    title:"Home",
    link:'/'
  },
  {
    title:"About Us",
    link:'/about-us'
  },
  {
    title:"Packages",
    link:'/packages'
  },
  // {
  //   title:"Blogs",
  //   link:"/blogs"
  // },
  {
    title:"Oman VISA",
    link:"/oman-visa"
  },
  {
    title:"Contact Us",
    link:"/contact-us"
  }
]

const contact_info = {
  email:"info@xclusiveoman.com",
  phone:"+91 9540111207",
  address:"128-A, D-Mall, NSP, Delhi-110034"
}


  const homedata = {foofterdata,nav_data,contact_info}
  
  export default homedata;