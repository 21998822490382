import React from 'react';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';


const WhatsAppChat = ({ phoneNumber, message }) => {
  const whatsappURL = `https://wa.me/${phoneNumber}`;

  return (
    <Box 
    sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: 1000,
        '& > :not(style)': { m: 1 },
      }}    >
    {/* <Fab color="" aria-label="WhatsApp chat"> */}
      {/* <AddIcon /> */}
      {/* <WhatsappShareButton
      url={whatsappURL}
      title={message} // Custom message
      separator=" - "  // Optional separator between message and URL
    >
      <WhatsappIcon size={40} round={true} /> */}
    {/* </WhatsappShareButton> */}
    {/* </Fab> */}
    <a
      href={whatsappURL}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: 'inline-block',
        backgroundColor: '#25D366',
        color: 'white',
        borderRadius: '50%',
        padding: '10px',
        fontSize: '24px',
        textDecoration: 'none',
      }}
    >
      <WhatsappIcon size={40} round={true} />
      </a>
  </Box>

  
  );
};

export default WhatsAppChat;
