import { api_route } from "../Api-Config/config";
import axiosInstance from "./axios";




const CreateContact = async(payload)=>{
    return axiosInstance.post(api_route+"/contact_us",payload);
}

const contactUsAPi = {CreateContact}
export default contactUsAPi;