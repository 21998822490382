import React, { useContext, useState } from 'react'
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import contactUsAPi from '../../services/contact_usService';
import { Button } from 'react-bootstrap';
import SubmitAlert from '../common/successfulMessage';
import useTimeoutToggle from '../../hooks/useTimeoutToggle';
import toggleWithTimeout from '../../Constant/functions/toggleWithTimeout';
import SimpleCaptchaForm from '../common/SimpleCaptchaForm';
import { GlobalContext } from '../../contexts/GlobalState';
import { SuccessModal } from '../common/SuccessModal';

export const ContactUsForm = () => {
    const {handleReset,isHuman,setIsHuman} = useContext(GlobalContext)
    const [loading, setLoading] = useState(false);
    const [success,setSuccess] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const handleCloseSucess = () => {setOpenSuccess(false);}
    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const contactSchema = Yup.object().shape({
        first_name: Yup.string().required("First Name is required"),
        last_name: Yup.string().required("Last Name is required"),
        phone_number: Yup.string()
            // .required("Mobile number is required")
            .matches(phoneRegExp, "Mobile number is not valid")
            .min(10, "Mobile number is not valid")
            .max(10, "Mobile number not valid"),
        email: Yup.string().required(" Email is required"),
        company_name: Yup.string(),
        message: Yup.string()
    });
    const formik = useFormik({
        initialValues: {
            "first_name": "",
            "last_name": "",
            "email": "",
            "phone_number": "",
            "fathers_name": "",
            company_name: "",
            message: ""
        },
        validationSchema: contactSchema,
        onSubmit: async (values, { resetForm, setErrors }) => {
            // handleSubmitCaptch();
                 if (!isHuman) {
            alert("Please solve the CAPTCHA correctly");
            return;
        }
            setLoading(true);
            console.log(values);
            try {
                console.log(values, "all values");
                const response = await contactUsAPi.CreateContact(values);
                console.log(response.data);
                resetForm();
                setOpenSuccess(true);
                setLoading(false);
                setIsHuman(false);
                handleReset();
                // toggleWithTimeout(setSuccess, 5000, true)
            } catch (error) {
                // resetForm();
                setErrors({ afterSubmit: error.message });
            }
        },
    });

    const {
        values,
        touched,
        errors,
        handleSubmit,
        getFieldProps,
        setFieldValue,
    } = formik;
    console.log(touched, "----->", errors, "=====>", values)
    return (
        <div class="col-md-6">
            <div class="contact-from-wrap">
                <div class="section-heading">
                    <h5 class="dash-style">GET IN TOUCH</h5>
                    <h2>We’d love to hear from you!  </h2>
                    <p>
                        We’re here to make your journey to Oman exceptional. Whether you’re planning a holiday, exploring business opportunities, or have other ideas in mind, feel free to drop us a line. Our commitment is to respond promptly to all inquiries.
                    </p>
                </div>
                <form class="contact-from">
               {success && <SubmitAlert
                message={"Message Sent successful"}
                render={success}
                />}
                    <p>
                       First Name*
                       <input
                            type="text"
                            name="first_name"
                            placeholder="Enter First Name*"
                            required
                            {...getFieldProps('first_name')}
                        />
                        {touched.first_name && errors.first_name ? (
                            <div className="error-text">{errors.first_name}</div>
                        ) : null} 
                    </p>
                    <p>
                        Last Name*
                        <input
                            type="text"
                            name="name"
                            placeholder="Enter Last Name*"
                            {...getFieldProps('last_name')}
                        />
                        {touched.last_name && errors.last_name ? (
                            <div className="error-text">{errors.last_name}</div>
                        ) : null}
                    </p>
                    <p>
                        Company Name
                        <input
                            type="text"
                            name="name"
                            placeholder="Company Name"
                            {...getFieldProps('company_name')}
                        />
                        {touched.company_name && errors.company_name ? (
                            <div className="error-text">{errors.company_name}</div>
                        ) : null}
                    </p>
                    <p>
                        Email Id*
                        <input
                            type="email"
                            name="email"
                            placeholder="Enter Email Id*"
                            {...getFieldProps('email')}
                        />
                        {touched.email && errors.email ? (
                            <div className="error-text">{errors.email}</div>
                        ) : null}
                    </p>
                    <p>
                        Mobile
                        <input
                            type="number"
                            name="email"
                            placeholder="Enter Mobile Number"
                            {...getFieldProps('phone_number')}
                        />
                        {touched.phone_number && errors.phone_number ? (
                            <div className="error-text">{errors.phone_number}</div>
                        ) : null}
                    </p>
                    <p>
                        Message
                        <textarea
                            rows="8"
                            placeholder="Start message"
                            {...getFieldProps('message')}
                        ></textarea>
                    </p>
                    <SimpleCaptchaForm
                    />
                   { <p>
                        <Button className="button-primary"
                            onClick={handleSubmit}
                            disabled={!isHuman}
                            style={{ background:`${!isHuman ? '#229b92' : null}`}}
                        >SEND MESSAGE</Button>
                    </p>}
                </form>
            </div>
            <SuccessModal
      title={"Enquire Add Succesfull"}
      open={openSuccess}
      onClose={handleCloseSucess}
      />
        </div>
    )
}
