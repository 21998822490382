import { api_route } from "../Api-Config/config";
import axiosInstance from "./axios";




const CreateEnquire = async(payload)=>{
    return axiosInstance.post(api_route+"/enquire",payload);
}

const enquireApi = {CreateEnquire}
export default enquireApi;