function formatNumberWithCommas(number) {
    if (typeof number !== 'number') {
      // throw new Error('Input must be a number');
      return number
    }
  
    // Convert the number to a string with commas
    return number.toLocaleString('en-IN'); // Indian number system
  }
  
  export default formatNumberWithCommas;