import React, { useContext, useState } from "react";
import "./login.css";
import { VisaBookModal } from "../Components/visa/visaBookModal";
import { Layout } from "../Layout/Layout";
import { Terms_condition } from "../Components/visa/terms_condition";
import { VisaApplyForm } from "../Components/visa/VisaApplyForm";
import { BestChoiceTable } from "../Components/visa/BestChoiceTable";

export const Visa = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Layout title={"Oman VISA"}>
      <>
        <main id="content" className="site-main">
          <section className="inner-banner-wrap">
            <div
              className="inner-baner-container visa-banner"
              style={{ backgroundImage: "url(image/img/visa2.png)" }}
            >
              <div className="container">
                <div className="inner-banner-content">
                  <h1 className="inner-title">Oman Visa</h1>
                </div>
              </div>
            </div>
            <div className="inner-shape"></div>
          </section>

          <div className="single-tour-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="single-tour-inner">
                    {/* <div className="tab-container"> */}
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {/* <li className="nav-item">
                          <a className="nav-link active" id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">Details</a>
                        </li> */}
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="overview"
                          role="tabpanel"
                          aria-labelledby="overview-tab"
                        >
                          <h3>Planning a trip to Oman?</h3>
                          <div className="overview-content">
                            <p>
                              {/* Planning a trip to Oman? */}
                               Here’s what you need to
                              know about obtaining an Oman visa as an Indian
                              citizen.
                            </p>
                            <h4 className="visa-sub-head">
                              Oman Visa Requirements:
                            </h4>


                            - <strong>Passport:</strong> A scanned copy of
                            your passport’s front and back pages. Passport
                            must be valid for at least 6 months from your
                            intended date of arrival in Oman.


                            <p>- <strong>Photo:</strong> A recent passport-size
                              photo taken on a plain background</p>




                            {/* <p className="passport"> - <strong>Passport:</strong> A scanned copy of
                              your passport’s front and back pages. Passport
                              must be valid for at least 6 months from your
                              intended date of arrival in Oman.
                            </p>
                            <p className="photo"> - <strong>Photo:</strong>A recent passport-size
                              photo taken on a plain background</p> */}

                            {/* <ul>
                              <li>
                                - <strong>Passport:</strong> A scanned copy of
                                your passport’s front and back pages. Passport
                                must be valid for at least 6 months from your
                                intended date of arrival in Oman.
                              </li>
                              <li>
                                - <strong>Photo:</strong>A recent passport-size
                                photo taken on a plain background
                              </li>
                            </ul> */}

                            <h4 className="visa-sub-head">Best Choice</h4>
                            
                            <div className="bastChoise-table">
                              <BestChoiceTable/>
                             
                            </div>

                            <h4 className="visa-sub-head">
                              How to Apply for an Oman E-Visa
                            </h4>
                            <p>
                              Xclusive Oman makes it easy to obtain your Oman
                              visa with a simple, convenient online  process.
                            </p>
                            
                            <h4 className="visa-sub-title">Step 1: Upload Documents</h4>
                            <p>
                              Visit XclusiveOman.Com and easily upload a
                              passport-size photo and the front and back pages
                              of your passport.
                            </p>
                            <h4 className="visa-sub-title">Step 2: Finalize and Pay</h4>
                            <p>
                              Review your details and pay using a convenient
                              payment option.
                            </p>
                            <h4 className="visa-sub-title">Step 3: Receive Your Visa</h4>
                            <p>
                              Once approved, your e-visa will be sent to you by
                              email. Print it to present at immigration
                              checkpoints upon arrival in Oman.
                            </p>
                          </div>

                          <div>
                            <h4 className="visa-sub-head">Important Notes:</h4>
                            <p>
                              You may be eligible for visa-free entry if you
                              meet one of the following conditions:
                            </p>
                            <p><span className="number-visa">1.</span> You hold a valid residence
                              or entry visa for at least 6 months in one of
                              these countries: the USA, Canada, Australia, the
                              UK, Schengen Zone countries, or Japan .
                            </p>
                            <p><span className="number-visa">2.</span> You are a resident of a Gulf
                            Cooperation Council (GCC) country.</p>


                            {/* <ul>
                              <li>
                                <strong>1.</strong> You hold a valid residence
                                or entry visa for at least 6 months in one of
                                these countries: the USA, Canada, Australia, the
                                UK, Schengen Zone countries, or Japan .
                              </li>
                              <li>
                                <strong>2.</strong> You are a resident of a Gulf
                                Cooperation Council (GCC) country
                              </li>
                            </ul> */}
                          </div>

                          {/* <div>
                            <h4 className="visa-sub-head">
                              Terms & Conditions
                            </h4>
                          </div> */}

                          <Terms_condition/>

                          
                        

                          {/* <div>
                            <h4 className="information">
                              <span>1.</span> Information We Collect
                            </h4>
                          </div>

                          <div>
                            <h4>Personal information:</h4>
                            <p>
                              Personal information is information or an opinion,
                              whether true or not and whether recorded in a
                              material form or not, about an individual who is
                              identified or reasonably identifiable. When you
                              contact us through the “Book Now” and/or "Contact
                              Us" form, we may collect personal information,
                              such as your name, email address, phone number,
                              and any other information you provide in your
                              communication.
                            </p>
                          </div>

                          <div>
                            <h4>Non-Personal Information:</h4>
                            <p>
                              We may collect non-personal information about your
                              visit to our website, such as the pages you viewed
                              and time spent on each page. This information
                              helps us improve our website and provide a better
                              user experience.
                            </p>
                          </div> */}


                        </div>
                        {/* <div className="col-sm-12">
                                       <div className="form-group submit-btn">
                                          <Button onClick={handleShowBookModal} className="button-primary  bookTripBtn" id="applyVisabutton" type="submit" name="submit" >Book VISA</Button>
                                       </div>
                                    </div> */}
                      </div>
                    {/* </div> */}
                  </div>
                </div>
                <VisaApplyForm/>
              </div>
            </div>
          </div>
        </main>
        <VisaBookModal open={open} handleClose={handleClose} />
      </>
    </Layout>
  );
};

