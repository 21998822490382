import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Layout } from '../Layout/Layout';
import packagesApi from '../services/packagesServices';
import { TabContainer } from '../Components/Packages/tabContainer';
import { GlobalContext } from '../contexts/GlobalState';
import formatNumberWithCommas from '../Constant/functions/currencyMaker';

export const Packagesdetails = () => {
   const { handleShowBookModal } = useContext(GlobalContext);

   const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
   const [details, setDetails] = useState()
   const getPackageDetails = async (id) => {
      try {
         const response = await packagesApi.packageDetails(id);
         console.log(response.data.data)
         let data = response.data.data;
         let days = data.day_by_day_schedule.sort((a, b) => a.day_number - b.day_number)
         console.log(days,"=====>")
         setDetails({...data,day_by_day_schedule: days})
      }
      catch (e) {
         console.error(e)
      }
   }
   useEffect(() => {
      if (id) {
         getPackageDetails(id)
      }
   }, [id])

   return (
      <Layout title={"Package details"}>
         <>
            <main id="content" className="site-main">
               <section className="inner-banner-wrap">
                  <div className="inner-baner-container" style={{ backgroundImage: `url(${details?.thumbnail})` }}>
                     <div className="container">
                        <div className="inner-banner-content">
                           <h1 className="inner-title">
                              {details?.package_name}
                           </h1>
                        </div>
                     </div>
                  </div>
                  <div className="inner-shape"></div>
               </section>
               <div className="single-tour-section">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-8">
                           <div className="single-tour-inner">
                              <h2>{details?.package_name}</h2>
                              <figure className="feature-image">
                                 <img
                                    // src="../image/img/image-5.jpg" 
                                    src={details?.thumbnail}
                                    alt={details?.package_name}
                                 />
                                 <div className="package-meta text-center">
                                    <ul>
                                       <li>
                                          <i className="far fa-clock"></i>
                                          {/* 6 days / 5 night */}
                                          {details?.duration}
                                       </li>
                                       <li>
                                          <i className="fas fa-user-friends"></i>
                                          {/* People: 4 */}
                                          People: {details?.maximum_group_size}
                                       </li>
                                       <li>
                                          <i className="fas fa-map-marked-alt"></i>
                                          {details?.destination}
                                       </li>
                                    </ul>
                                 </div>
                              </figure>

                              <TabContainer
                                 {...details}
                              />


                              {details?.galary_images?.length !== 0 &&  <div className="single-tour-gallery">
                                 <h3>GALLERY / PHOTOS</h3>
                                 <div className="single-tour-slider">
                                    <Carousel autoPlay
                                       showArrows={false}
                                    >

                                      {details?.galary_images?.map((images,i)=> 
                                     <div className="single-tour-item"
                                     key={images?.id}
                                     >
                                     <figure className="feature-image">
                                        <img src={images?.image_url} alt={details?.package_name+i} />
                                     </figure>
                                  </div>
                                    )}
                                       {/* <div className="single-tour-item">
                                       <figure className="feature-image">
                                          <img src="../image/img/image-9.avif" alt="" />
                                       </figure>
                                    </div>

                                    <div className="single-tour-item">
                                       <figure className="feature-image">
                                          <img src="../image/img/image-10.avif" alt="" />
                                       </figure>
                                    </div>
                                    <div className="single-tour-item">
                                       <figure className="feature-image">
                                          <img src="../image/img/image-9.avif" alt="" />
                                       </figure>
                                    </div>
                                    <div className="single-tour-item">
                                       <figure className="feature-image">
                                          <img src="../image/img/image-10.avif" alt="" />
                                       </figure>
                                    </div> */}
                                    </Carousel>

                                 </div>
                              </div>}
                           </div>
                        </div>
                        <div className="col-lg-4">
                           <div className="sidebar">
                              <div className="package-price">
                                 <h5 className="price">
                                    <span>₹{formatNumberWithCommas(details?.discount_price) }</span> / per person 
                                    <br/>
                                    Plus Taxes as Applicable
                                 </h5>
                                 <div className="start-wrap">
                                    {/* <div className="rating-start" title="Rated 5 out of 5">
                                       <span style={{ "width": "60%" }}></span>
                                    </div> */}
                                 </div>
                              </div>
                              <div className="widget-bg booking-form-wrap">
                                 <h4 onClick={handleShowBookModal} className="bg-title">ENQUIRE NOW</h4>
                                 {/* <form className="booking-form">
                                    <div className="row">
                                       <div className="col-sm-12">
                                          <div className="form-group">
                                             <input name="name_booking" type="text" placeholder="Full Name" />
                                          </div>
                                       </div>
                                       <div className="col-sm-12">
                                          <div className="form-group">
                                             <input name="email_booking" type="text" placeholder="Email" />
                                          </div>
                                       </div>
                                       <div className="col-sm-12">
                                          <div className="form-group">
                                             <input name="phone_booking" type="text" placeholder="Number" />
                                          </div>
                                       </div>
                                       <div className="col-sm-12">
                                          <div className="form-group">
                                             <input className="input-date-picker" type="text" name="s" autocomplete="off" readonly="readonly" placeholder="Date" />
                                          </div>
                                       </div>
                                       <div className="col-sm-12">
                                          <h4 className="">Add Options</h4>
                                       </div>
                                       <div className="col-sm-6">
                                          <div className="form-group">
                                             <label className="checkbox-list">
                                                <input type="checkbox" name="s" />
                                                <span className="custom-checkbox"></span>
                                                Tour guide
                                             </label>
                                          </div>
                                       </div>
                                       <div className="col-sm-6">
                                          <div className="form-group">
                                             <label className="checkbox-list">
                                                <input type="checkbox" name="s" />
                                                <span className="custom-checkbox"></span>
                                                Insurance
                                             </label>
                                          </div>
                                       </div>
                                       <div className="col-sm-6">
                                          <div className="form-group">
                                             <label className="checkbox-list">
                                                <input type="checkbox" name="s" />
                                                <span className="custom-checkbox"></span>
                                                Dinner
                                             </label>
                                          </div>
                                       </div>
                                       <div className="col-sm-6">
                                          <div className="form-group">
                                             <label className="checkbox-list">
                                                <input type="checkbox" name="s" />
                                                <span className="custom-checkbox"></span>
                                                Bike rent
                                             </label>
                                          </div>
                                       </div>
                                       <div className="col-sm-12">
                                          <div className="form-group submit-btn">
                                             <input type="submit" name="submit" value="Boook Now" />
                                          </div>
                                       </div>
                                    </div>
                                 </form> */}
                              </div>
                              {/* <div className="widget-bg information-content text-center">
                                 <h5>TRAVEL TIPS</h5>
                                 <h3>NEED TRAVEL RELATED TIPS & INFORMATION</h3>
                                 <p>Mollit voluptatem perspiciatis convallis elementum corporis quo veritatis aliquid blandit, blandit torquent, odit placeat. </p>
                                 <a href="#" className="button-primary">GET A QUOTE</a>
                              </div>
                              <div className="travel-package-content text-center" style={{ "backgroundImage": "url(assets/images/img11.jpg)" }}>
                                 <h5>MORE PACKAGES</h5>
                                 <h3>OTHER TRAVEL PACKAGES</h3>
                                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus.</p>
                                 <ul>
                                    <li>
                                       <a href="#"><i className="far fa-arrow-alt-circle-right"></i>Vacation packages</a>
                                    </li>
                                    <li>
                                       <a href="#"><i className="far fa-arrow-alt-circle-right"></i>Honeymoon packages</a>
                                    </li>
                                    <li>
                                       <a href="#"><i className="far fa-arrow-alt-circle-right"></i>New year packages</a>
                                    </li>
                                    <li>
                                       <a href="#"><i className="far fa-arrow-alt-circle-right"></i>Weekend packages</a>
                                    </li>
                                 </ul>
                              </div> */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </main>
         </>
      </Layout>
   )
}
