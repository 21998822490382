import React from "react";

export const BestChoiceTable = () => {
  return (
    <table>
    <tr className="choice-section">
      <th className="choice-heading">VISA type</th>
      <th className="choice-heading">Lenght of stay</th>
      <th className="choice-heading">Validity</th>
      <th className="choice-heading">Entry</th>
      <th className="choice-heading">Special Price</th>
    </tr>

    <tr className="choice-section">
      <td className="choice-data">E-visa</td>
      <td className="choice-data">10 days</td>
      <td className="choice-data">90 days from the issue date</td>
      <td className="choice-data">Single</td>
      <td className="choice-data">₹ 2,400</td>
    </tr>

  </table>
  );
};
