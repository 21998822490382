import { Navigate, useRoutes } from "react-router-dom";

// layout

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";

// components


import { Home } from "../pages/home";
import { About } from "../pages/About";
import { PageNotFound } from "../pages/404";
import { Packages } from "../pages/Packages";
import { Blogs } from "../pages/Blogs";
import { Packagesdetails } from "../pages/Packages-details";
import { Visa } from "../pages/Visa";
import { Contact } from "../pages/Contact";
import { Blogsdetails } from "../pages/Blogsdetails";
import { Login } from "../pages/Login";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";

const Router = () => {
  return useRoutes([
    {
      path: "/",
      element: <Home/>,
      // children: [
      //     { element : <h1>Landing page</h1> },
      //     { path : 'about-us', element : <h1>About Us</h1> },
      //     { path : 'contact-us', element : <h1>Contact Us</h1> },
      //     { path : 'faqs', element : <h1>Frequently asked question</h1> },
      // ]
    },
    {
      path:"/privacy-policy",
      element:<PrivacyPolicy/>
    },
    {
      path:"/about-us",
      element:<About/>
    },

    {
      path:"/packages",
      element:<Packages/>,
      children: [  
      ]
    },

    // {
    //   path:"/blogs",
    //   element:<Blogs/>
    // },

    {
      path:"/package-details",
      element:<Packagesdetails/>
    },
    
    {
      path:"/oman-visa",
      element:<Visa/>
    },

    {
      path:"/contact-us",
      element:<Contact/>
    },

    {
      path:"/blogs-details/:id",
      element:<Blogsdetails/>
    },

    {
      path:"/login",
      element:<Login/>
    },



    
    // {
    //   path: "auth",
    //   children: [
    //     {
    //       path: "",
    //       element: <Navigate to="/auth/login" />,
    //     },
    //     {
    //       path: "login",
    //       element: (
    //         <GuestGuard>
    //           <Login />
    //         </GuestGuard>
    //       ),
    //     },
    //   ],
    // },
    // {
    //   path: "login",
    //   element: <Navigate to="/auth/login" />,
    // },
    // {
    //   path: "forget-password",
    //   element: <ForgetPassword />,
    // },
    // {
    //   path: "admin",
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     // { path: "", element: <Navigate to="/admin/dashboard" /> },
       
    //   ],
    // },
    // {path: "profile/:_id", element: <AddNewClients /> }  ,
    // { path: "insurenceBuy/:response", element: <InsurenceBuy /> },
    // {
    //   path: "dashboard",
    //   element: <Navigate to="/admin/dashboard" />,
    // },

    // { path: "buy-insurance", element: <BuyInsurance /> },
    // {
    //   path: "policy",
    //   children: [
    //     { path: "insurer", element: <Insurer replace /> },
    //     { path: "insurance-form", element: <PersonalDetails /> },
    //   ],
    // },
    // {
    //   path: "api/employee",
    //   children: [{ path: "set-new-password", element: <SetNewPassword /> }],
    // },
    {
      path: "*",
      element: <PageNotFound/>,
    },
  ]);
};

export default Router;
