import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export const Crousal = () => {
  return (
    <Carousel autoPlay
    showArrows={false}
    >
    <div class="testimonial-item text-center">
        <figure class="testimonial-img">
          <img src="./assets/images/img20.jpg" alt="" />
        </figure>
        <div class="testimonial-content">
          <p>
            " Our trip to Oman was nothing short of spectacular!
            Xclusive Oman made the entire experience seamless, from
            booking to exploring the most breathtaking locations.
            Every detail was handled with care, and we had the
            adventure of a lifetime. Highly recommended for anyone
            looking for a personalized, unforgettable holiday! "
          </p>
          <cite>
            Sakshi & Ankit
            {/*<span class="company">Travel Agent</span> */}
          </cite>
        </div>
      </div>
      <div class="testimonial-item text-center">
        <figure class="testimonial-img">
          <img src="assets/images/img21.jpg" alt="" />
        </figure>
        <div class="testimonial-content">
          <p>
            " Xclusive Oman crafted the perfect itinerary for our
            family vacation. From the stunning beaches to the rich
            cultural experiences, we were constantly in awe. The
            team was attentive to our needs and ensured everything
            was flawless. We couldn't have asked for a better
            holiday experience in Oman! "
          </p>
          <cite>
            Neha & Piyush
            {/*<span class="company">Travel Agent</span> */}
          </cite>
        </div>
      </div>
      <div class="testimonial-item text-center">
        <figure class="testimonial-img">
          <img src="assets/images/img22.jpg" alt="" />
        </figure>
        <div class="testimonial-content">
          <p>
            " Oman was a dream come true, thanks to Xclusive Oman!
            They took care of everything, making our trip
            stress-free and memorable. From the desert safaris to
            the luxurious resorts, every aspect exceeded our
            expectations. We're already planning our next trip with
            them!"
          </p>
          <cite>
            Akshay & Satya
            {/*<span class="company">Travel Guide</span> */}
          </cite>
        </div>
      </div>
    </Carousel>
  )
}
